.rating {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
}

.rating > li {
	padding-right: 4px;
}

.rating > li {
	width: 14px;
	height: 14px;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 2px !important;
	margin-left: 2px !important;
}

.rating > li:last-of-type {
	margin-right: 0;
}

.star-filled {
	background-image: url(../../assets/icons/star-filled.png);
}

.star-empty {
	background-image: url(../../assets/icons/star-empty.png);
}

.rating.enabled li {
	cursor: pointer;
}

.rating.enabled > li:hover,
.rating.enabled > li:hover ~ li {
	background-image: url(../../assets/icons/star-filled.png);
}

.enabled:hover {
	display: flex;
	flex-direction: row-reverse;
}

.enabled:hover .star-filled {
	background-image: url(../../assets/icons/star-empty.png);
}
