.about-info__datePicker--container label {
  font-family: 'Poppins-Regular';
}
.about-info__datePicker--container label.Mui-focused {
  color: rgba(0, 0, 0, 0.54);
}

.about-info__datePicker--container button {
  padding: 3px;
}

.about-info__datePicker--container .MuiInputBase-root {
  padding-left: 8px;
}
