.service-card-width-image {
    position: relative;
    display: flex;
    background: #F9F9F9;
    border: 2px solid #F9F9F9;
    border-radius: 8px;
    width: min-content;
    overflow: hidden;

    &:not(:first-of-type) {
        margin-top: 12px;
    }

    &.selected {
        border-color: #00aedc;
    }

    .badge-new.is-mobile {
        left: 12px;
        right: unset;
    }

    .service-card-content {
        position: relative;
        display: flex;
        flex-flow: column;
        padding: 12px;
        gap: 12px;
    }

    .service-card-header {
        font-family: 'Poppins-Medium';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #190E2F;
    }

    .service-card-description {
        font-family: 'Poppins-Regular';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #474F5A;
        flex-grow: 1;
        width: 217px;
    }

    .service-detail {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #828282;
        white-space: nowrap;
    }

}

.badge-new {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 48px;
    height: 27px;
    right: 11px;
    top: 10px;
    background: #FF707A;
    border-radius: 4px;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}