.buffer {
  width: 100%;
  .MuiLinearProgress-dashedColorPrimary {
    background-image: radial-gradient(rgb(247, 120, 129) 0%, rgb(247, 120, 129) 16%, transparent 42%);
  };

  .MuiLinearProgress-barColorPrimary {
    background-color: rgb(239, 203, 104);
  };

  .MuiLinearProgress-colorPrimary {
    background-color: rgb(127, 212, 240);
  }

}

.loading_wrapper{
  &.loading_wrapper--lobby {
    p{
      color: white;
    }
  }

  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > p {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 7rem;
    color: #fff;
  }
  > svg {
    margin-bottom: 2rem;
    margin-top: -13rem;
  }
}

.envite-loader__wrapper {
  height: 135px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;


  .envite-loader_yellow {
    background-color: rgb(239, 203, 104);
    animation: progressAnimation 2s infinite;
  }

  .envite-loader_red {
    background-color: rgb(247, 120, 129);
    animation: progressAnimation 2s infinite;
    animation-delay: 1s;
  }

  div {
    width: 100px;
    height: 34px;
  }

  div.envite-loader_blue {
    width: 70px;
    max-width: 70px;
    background-color: rgb(127, 212, 240);
    animation: progressAnimation 2s infinite;
    animation-delay: 1.5s;
  }

  @keyframes progressAnimation {
    0% {
      width: 100%;
    }

    40% {
      width: 20%;
    }

    80% {
      width: 100%;
    }
  }
}

