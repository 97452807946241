.profile__review-field {
  width: 100%;
  height: 126px;
  padding: 24px;
  box-sizing: border-box;
  resize: none;
  border-radius: 8px;
  border: solid 1px rgba(218, 219, 221, 0.64);
  background-color: #ffffff;
  font-family: 'Poppins-Regular';
  font-size: 14px;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.32);
}

.profile__review-field::placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.profile__review-field:focus{
  outline: 0px solid #fff;
}

.write-review .top-part h2 {
  text-transform: unset;
  color: rgba(0, 0, 0, 0.56);
  font-family: 'Poppins-Medium';
  font-size: 14px;
}

.write-review {
  display: flex;
  flex-direction: column;
  position: relative;
}

.write-review h2 {
  margin-bottom: 0;
}

.write-review .top-part {
  margin-bottom: 8px;
  padding-top: 15px;
}

.write-review .rating li,
.write-review .rating img {
  width: 18px;
  height: 18px;
}

.profile-wrapper .profile button.post-button {
  width: 115px;
  height: 40px;
  padding: 12px 43px;
  border-radius: 12px;
  background-color: #6b869a;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  border: none;
  align-self: flex-end;
  pointer-events: none;
}

.profile-wrapper .profile button.post-button:disabled {
  background-color: var(--thin-gray);
  color: #0f3c5c;
}

.post-button--active {
  background-color: #00a1ff;
  cursor: pointer;
  pointer-events: all;
}
