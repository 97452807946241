.password-change-popup {
  overflow: hidden;
  height: 100vh;
  .play-button{
    z-index: 0;
  }
}
.background-blur {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  backdrop-filter: blur(12px);
}
.password-change__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .booking-popup {
    box-sizing: border-box;
    box-shadow: 0px 6px 30px rgba(0, 14, 253, 0.1);
    border-radius: 8px;
    .booking-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .key-icon {
          width: 36px;
          height: 36px;
          background: #B9D7E0;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          path {
            fill: white;
          }
        }
        h1.title {
          margin: 0;
          padding: 0;
          width: auto;
        }
      }
      .close-icon{
        cursor: pointer;
        margin-right: 5px;
      }
    }

    .MuiInputAdornment-root {
      cursor: pointer;
      svg {
        margin-right: -10px;
        
      }
    }
  }
  label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    padding: 5px 0;
  }
  .MuiTextField-root {
    padding: 5px 0;
    .MuiInputBase-root {
      border: 1px solid rgba(218, 219, 221, 0.64);
      border-radius: 8px;
    }
  }
  .booking-popup {
    .booking-content {
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  
  .password-change__invalid {
    color: red;
  }
  .widget-registration-input {
    width: 100%;
  }
  .password-change__button {
    width: 152px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #00aedc;
    border: none;
    font-family: 'Poppins-SemiBold';
    color: white;
    margin: 10px 0;
    margin-left: auto;
    cursor: pointer;
    font-size: 14px;
  }
  .password-change__button:disabled {
    cursor: unset;
    background: rgba(218, 219, 221, 0.4);
    color: rgba(25, 14, 47, 0.7)
  }
}