.widget-choose-card-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
}

.widget-credit-card-last-digits {
  
}

.widget-choose-card-add-button {
  opacity: 0.7;
  font-size: 14px;
  color: #00a1ff !important;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 20px;
}
