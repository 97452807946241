.widget-payment-error-container {
  height: 32px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 16px;
  margin-bottom: -6px;
}

.widget-payment-error-background {
  opacity: 0.15;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  top: 0;
  background-color: #f6725c;
}

.widget-payment-error-text {
  font-family: "Poppins-Regular";
  font-size: 12px;
  margin-left: 10px;
  color: #FF707A;
}

.widget-payment-warn-icon img{
  width: 14px;
  height: 14px;
}

.widget-payment-warn-icon {
  margin-top: 5px;
}