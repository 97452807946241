.calendar .DayPicker-NavButton.DayPicker-NavButton--prev,
.calendar .DayPicker-NavButton.DayPicker-NavButton--next {
  background-size: 100%;
  width: 24px;
  height: 24px;
}

.calendar .DayPicker-NavButton.DayPicker-NavButton--prev {
  background-image: url(../../assets//icons/calendar-perv.png);
  margin-right: 32px;
}

.calendar .DayPicker-NavButton.DayPicker-NavButton--next {
  background-image: url(../../assets//icons/calendar-next.png);
}

.calendar .DayPicker-Caption > div {
  font-size: 14px;
  color: #190e2f;
  font-family: 'Poppins-Medium';
  padding-left: 16px;
}

.calendar .DayPicker {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.calendar .DayPicker-Weekday {
  font-weight: bold;
}

.calendar .DayPicker-Day {
  font-family: 'Poppins-Regular';
  width: 20px;
  height: 20px;
}

.calendar .DayPicker-Day:not(.DayPicker-Day--disabled) {
  color: #474f5a;
  font-size: 14px;
  font-family: 'Poppins-Regular';
}

.calendar .DayPicker-Day--today{
  color: #00aedc !important;
}

.calendar .DayPicker-Day--today.DayPicker-Day--disabled{
  opacity: 0.3;
}

.calendar .DayPicker-Day--today.DayPicker-Day--selected,
.calendar .DayPicker-Day--today:hover{
  color: #fff !important;
  background: #00aedc;
}

.calendar .DayPicker-Day.DayPicker-Day--outside {
  color: rgba(10, 40, 61, 0.12);
}

.calendar
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
.calendar .DayPicker-Day.DayPicker-Day--selected {
  color: var(--profile-main-text-color);
  background-color: var(--profile-main-color);
  background-image: var(--profile-main-color);
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  /* color: black; */
  background-color: #00aedc;
}

.calendar .DayPicker,
.calendar .DayPicker-wrapper,
.calendar .DayPiker-NavBar,
.calendar .DayPicker-Months,
.calendar .DayPicker-Month {
  width: 290px;
  height: 270px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 50px;
}

@media (max-height: 635px) {
  .calendar .DayPicker,
  .calendar .DayPicker-wrapper,
  .calendar .DayPiker-NavBar,
  .calendar .DayPicker-Months,
  .calendar .DayPicker-Month {
    width: 250px;
    height: 230px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px;
  }
}

@media (max-width: 480px) {
  .calendar .DayPicker,
  .calendar .DayPicker-wrapper,
  .calendar .DayPiker-NavBar,
  .calendar .DayPicker-Months,
  .calendar .DayPicker-Month {
    margin-bottom: 0px;
    overflow: hidden;
    height: 250px;
  }
}

.calendar .DayPicker-Caption,
.DayPicker-NavBar span {
  margin-left: 5px;
}

.calendar .DayPicker-NavBar span:first-of-type {
  margin-right: 20px;
}

.calendar .DayPicker-NavBar span:last-of-type {
  margin-right: -10px;
}

.calendar .DayPicker-Caption {
  margin-left: 0;
}

.calendar * {
  outline: none;
}

.calendar-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-blur {
  filter: blur(3px);
}
