.cancel-next-message {
	display: flex;
	align-items: center;
	justify-content: center;
}

.profile-wizard-next-error {
	color: #F00;
}

.profile-flow-stack .widget-signup-inner-bottom {
	/*position: relative !important;*/
	width: 100%;
	margin-top: -5px;
	/*margin-top: 20px;*/
}

.profile-flow-stack .widget-signup-inner-bottom .widget-signup-headline {
	font-size: 14px;
	font-family: 'Poppins-Medium';
	color: rgba(0, 0, 0, 0.56);
	margin-bottom: 12px;
}

.profile-flow-stack hr:not(.side-hr) {
	width: calc(100% + 50px);
	margin-left: -25px;
	border: none;
	border-bottom: 4px solid var(--gray-1);
}

.profile-flow-stack-hr {
	width: calc(100% + 50px);
	margin-left: -25px;
	border: none;
	border-bottom: 4px solid var(--gray-1);
}

.profile-flow-stack button:focus {
	outline: none;
}

.profile-flow-stack .hr-margin-top {
	margin-top: 25px;
}

.profile-flow-stack .choosen-gig {
	margin-top: 10px;
}

.time-error {
	font-size: 12px;
    margin: 3px;
    color: #ff707a;
    font-family: 'Poppins-Medium';
}