.about-info-form .MuiFormControl-root.MuiTextField-root {
	width: 100%;
	margin-bottom: 16px;
	padding-top: 1px;
}

.about-info-form fieldset {
	border-radius: 8px;
	border: solid 1px rgba(218, 219, 221, 0.64);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
	border-color: rgba(218, 219, 221, 0.64) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
	border-color: rgba(218, 219, 221, 0.64) !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
	border-color: #f44336 !important;
}

.about-info-form .about-info-fields__wrapper > .inputs-wrapper{
	height: 290px;
	overflow-y: auto;
	padding-top: 6px;
}

.about-info-form .about-info-fields__wrapper > .inputs-wrapper::-webkit-scrollbar {
	width: 2px;
}
.about-info-form .about-info-fields__wrapper > .inputs-wrapper::-webkit-scrollbar-track {
	background: transparent;
}
.about-info-form .about-info-fields__wrapper > .inputs-wrapper::-webkit-scrollbar-thumb {
	border-radius: 1.2px;
	border: 4px solid #dadbdd;
}


.profile-flow-stack .cancel-next-message > .back-btn{
	background-color: rgba(218, 219, 221, 0.4);
	color: rgba(25, 14, 47, 0.7);
}

.about-info-form .MuiFormControl-root.MuiTextField-root input {
	padding: 12px 15px 12px 15px;
	color: rgba(0, 0, 0, 0.32);
	font-size: 14px;
	font-family: "Poppins-Regular";
}

.about-info-form .MuiFormLabel-root {
	margin-top: 1px;
}
.about-info-form .MuiFormControl-root.MuiTextField-root input,
.about-info-form .MuiFormLabel-root {
	font-size: 16px;
}

.about-info-form .MuiFormControl-root.MuiTextField-root input {
	font-size: 14px;
}

.about-info-form .info {
	opacity: 0.64;
	font-size: 14px;
	font-family: 'Poppins-Regular';
	color: #190e2f;
	margin: 20px 0 16px;
	text-transform: none;
}

.react-tel-input .form-control.about-info__phone--input {
	width: 100%;
	height: 41px;
	border: 1px solid #e7e8e9;
	color: rgba(0, 0, 0, 0.32);
	font-size: 14px;
	font-family: 'Poppins-Regular';
	border-radius: 8px;
}
.react-tel-input .form-control.about-info__phone--input:hover {
	border: 1px solid #e7e8e9;
}
.react-tel-input .form-control.invalid-number {
	border: 1px solid #f44336!important;
}
.react-tel-input .form-control.invalid-number.about-info__phone--input:hover {
	border: 1px solid #f44336;
}
.react-tel-input .form-control.about-info__phone--input:focus {
	border: 1px solid #e7e8e9;
	box-shadow: none;
}

.react-tel-input .form-control.invalid-number:focus {
	border: 2px solid #f44336!important;
	box-shadow: none!important;
}

.about-info__phone--container.react-tel-input .special-label {
	left: 11px;
	top: -9px;
	color: #757575;
	font-family: 'Poppins-Regular';
	font-size: 12px;
}

.about-info__phone--container {
	margin-bottom: 16px;
}

.about-info__phone--container_invalid .special-label {
	color: #f44336!important;
}