.review {
	display: flex;
}

.image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 67px;
	height: 65px;
	border: 9.3px solid #f3f8f9;
	margin-right: 36px;
	border-radius: 50%;
	overflow: hidden;
}

.image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.top-part {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.review .author {
	color: #000;
	font-size: 14px;
	font-family: 'Poppins-Medium';
	margin-bottom: 5px;
	text-transform: capitalize;
}

.review .content,
.review .content .top-part {
	width: 100%;
}

main > .content-wrapper > .sections > section.reviews p {
	color: #9b9ea3;
	line-height: 21px;
	opacity: 1;
}

.review .top-part-wrapper > .top-part{
	display: none;
}

@media screen and (max-width: 550px){
	.review{
		flex-direction: column;
	}
	.review .content{
		margin-top: 17px;
	}
	.review .content .top-part{
		display: none;
	}
	.review .top-part-wrapper > .top-part{
		display: block;
		padding-top: 3px;
	}
	.review .top-part-wrapper{
		display: flex;
		flex-direction: row;
	}
	.review .top-part-wrapper > .image-container{
		margin-right: 12px;
	}

	.review .top-part-wrapper > .top-part > .author{
		font-size: 16px;
	}

	.review .top-part-wrapper > .top-part > .rating{
		position: relative;
		top: 5px;
	}

}
