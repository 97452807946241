.profile-reviews .header-arrow h2 {
	margin-bottom: 25px;
}
.profile-reviews > ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.profile-reviews > ul > li:not(:last-of-type) {
	border-bottom: 1px solid #dadbdd;
	padding-bottom: 15px;
  	margin-bottom: 36px;
}

.pagination {
	list-style: none;
	display: flex;
	padding: 0;
	width: 260px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 34px;
	margin-top: 45px;
}


.pagination li.previous {
	margin-right: 10px;
}

.pagination li.next {
	margin-left: 10px;
}

.pagination li.previous,
.pagination li.previous a,
.pagination li.previous img,
.pagination li.next,
.pagination li.next a,
.pagination li.next img {
	width: 24px;
	height: 24px;
	outline: none;
	cursor: pointer;
}

.pagination-container {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--blue-2);
}

.pagination li,
.pagination a {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
}

.pagination li.active {
	width: 24px;
	height: 24px;
	border-radius: 2px;
	border: solid 0.5px #00aedc;
	background-color: rgba(185, 215, 224, 0.3);
	box-sizing: border-box;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.pagination > li:not(.next):not(.previous) > a{
	font-family: 'Poppins-Regular';
	font-size: 14px;
	line-height: 14px;
	color: #000;
	opacity: 0.72;
}

.pagination li.active > a{
	font-family: 'Poppins-Medium';
}

.pagination > li.previous,
.pagination > li.next{
	background: #f3f8f9;
	border-radius: 2px;
	position: relative;
}

.pagination > li.previous:after{
	display: block;
	content: "...";
	position: absolute;
	bottom: -2px;
	left: calc(100% + 20px);
	color: #c5d0d7;
}

.pagination > li.next:after{
	display: block;
	content: "...";
	position: absolute;
	bottom: -2px;
	right: calc(100% + 20px);
	color: #c5d0d7;
}

.pagination > li > a {
	margin-top: -1px;
}

.post-button-wrapper{
	text-align: right;
}

.post-button-wrapper > button{
	padding-left: 52.5px;
	padding-right: 52.5px;
	background: #00aedc;
	color: #fff;
	margin-top: 24px;
}


.reviews-tooltip {
	visibility: hidden;
	width: 183px;
	height: 32px;
	flex-grow: 0;
	border-radius: 4px;
	background-color: rgba(255, 112, 122, 0.1);
	color: #ff707a;
	font-size: 12px;
	font-family: 'Poppins-Regular';
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	position: absolute;
	transition: all .3s ease;
	opacity: 0;
	bottom:33px;

}

.write-review-textfield:hover >  .reviews-tooltip{
	visibility: visible;
	opacity: 1;
}

.write-review-textfield:focus-within > .reviews-mobile-tooltip{
	visibility: visible;
	opacity: 1;
}

.reviews-mobile-tooltip{
	width: 125px;
	height: 25px;
	flex-grow: 0;
	border-radius: 4px;
	background-color: rgba(255, 112, 122, 0.1);
	font-family: 'Poppins-Regular';
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	color: #ff707a;
	font-size: 8px;
	position: absolute;
	bottom:41px;
	transition: all .3s ease;
	visibility: hidden;
	opacity: 0;
}