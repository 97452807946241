.chat-slideshow__wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(28, 31, 36, 0.24);
    z-index: 10000;
    backdrop-filter: blur(12px);
    left: 0px;
    top: 0;
    -webkit-animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    display: flex;
    flex-direction: column;
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}


.chat-slideshow__wrapper .chat-slideshow__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6%;
    height: 80px;
}

.chat-slideshow__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-bottom: 10px;
}

.chat-slideshow__footer img,
.chat-slideshow__footer video {
    margin-right: 8px;
    border-radius: 12px
}

.chat-slideshow__main {
    display: flex;
    align-items: center;
    flex: 4;
    position: relative;
    max-height: calc(100% - 170px);
}

.chat-slideshow__main svg {
    margin: 0 5px;
    cursor: pointer;
    position: absolute;
    z-index: 999999;
}

.chat-slideshow__main .right-arrow__svg {
    right: 0;
}

.chat-slideshow__main svg:first-child{
    transform: rotate(180deg);
}

.chat-slideshow__main--content {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.chat-slideshow__main--content img,
.chat-slideshow__main--content video {
    max-width: 80%;
    max-height: 80%;
    border-radius: 8px;
}

.chat-slideshow__footer--previews {
    padding-left: 8px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.chat-slideshow__main--content img,
.chat-slideshow__footer--previews img{
    user-select: none;
}

.chat-slideshow__main--content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-slideshow__close {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    color: #FFFFFF;
    font-family: "Poppins-regular";
}
.chat-slideshow--count{
    font-size: 14px;
    color: #FFFFFF;
}
.chat-slideshow--download{
    cursor: pointer;
}
.rounded-arrow__disabled {
    opacity: 0.2;
    pointer-events: none;
}

.chat-slideshow__footer--previews img,
.chat-slideshow__footer--previews video {
    width: 80px;
    height: 80px;
    opacity: 0.3;
    cursor: pointer;
    object-fit: cover;
}

.chat-slideshow__footer--previews img.active,
.chat-slideshow__footer--previews video.active {
    opacity: 1;
}

@media screen and (max-width: 500px){
    .chat-slideshow__main--content img,
    .chat-slideshow__main--content video {
        max-width: 100%;
        max-height: 100%;
    }
}
