.singup-invitaion-info__wrapper{
    position: relative;
    width: 349px;
    height: 557px;
    background-color: #fff;
    border-radius: 12px;
    text-align: center;
    padding: 23px 24px;
    > p {
        text-align: center;
        width: 246px;
        margin: auto;
        margin: 24px auto;
        font-family: "Poppins-SemiBold";
        opacity: 1;
        color: rgba(25, 14, 47, 1);
        font-size: 16px;
    }
    > div:first-child {
        text-align: initial;
    }

    .btn-log-in {
        width: 280px;
        height: 44px;
        background-color: rgba(0, 174, 220, 1) !important;
        color: #fff !important;
    }
    
    
    .btn-log-in--disabled {
        background-color: rgba(242, 242, 242, 1) !important;
        color: rgba(190, 190, 190, 1) !important;
    }

    > span {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
}

.main-container.main-container--confirm-meeting .sign-in__live--header-text{
    margin-top: 15px;
    position: static;
}
.main-container.main-container--confirm-meeting .MuiButton-root.btn-log-in--disabled {
    background-color: #F2F2F2;;
}

.main-container.main-container--confirm-meeting .MuiButton-root {
    background-color: #00AEDC;
}
.main-container.main-container--confirm-meeting .MuiFormControl-root.sign-up-live {
    margin-top: 10px;
}
.sign-up__input--wrapper .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(218, 219, 221, 0.64);
}

.selected-email {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(120, 139, 255, 1);
    border-radius: 8px;
    padding: 4px;

    font-size: 14px;
    color: #fff;
}

.singup-invie-policy {
    font-size: 12px;
    font-family: "Poppins-Regular";
    text-align: center;
    color: rgba(25, 14, 47, 0.64);
    >span {
        font-family: "Poppins-Medium";
        text-decoration: underline;
        cursor: pointer;
    }
}

.MuiFormLabel-root.Mui-focused {
    color: rgba(25, 14, 47, 0.64) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-size: 12px !important;
}

.MuiInputBase-input {
    color: rgb(0,0,0) !important;
}