.progress-texts__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .active {
        color: rgba(0, 174, 220, 1);
        > svg > rect {
            fill: rgba(0, 174, 220, 1);
        }
    }

    .done {
        color: rgba(9, 215, 126, 1);
        > svg > rect {
            fill: rgba(9, 215, 126, 1);
        }
    }

    .upcoming {
        color: rgba(218, 219, 221, 1);
        > svg {
            > rect {
                fill: rgba(218, 219, 221, 1);
            }
            >path {
                fill: rgba(218, 219, 221, 1);
            }
        }
    }

}

.progress-texts-step {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "Poppins-Medium";
    > span {
        margin: 0 7px;
        white-space: nowrap;
    }
}