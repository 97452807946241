.gallery-contents {
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(10, 10%);
}

.gallery-contents li {
	height: 120px;
	margin-bottom: 8px;
	margin-right: 7px;
}

.gallery-contents li img,
.gallery-contents li .gallery-video-wrapper,
.gallery-contents li video {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 8px;
}

.gallery-contents li video:fullscreen {
	object-fit: contain;
}

.gallery-contents li:nth-child(9n - 8) {
	grid-column: 1 / span 2;
}

.gallery-contents li:nth-child(9n - 7) {
	grid-column: 3 / span 4;
}

.gallery-contents li:nth-child(9n - 6) {
	grid-column: 7 / span 4;
	margin-right: 0;
}

.gallery-contents li:nth-child(9n - 5) {
	grid-column: 1 / span 4;
}

.gallery-contents li:nth-child(9n - 4) {
	grid-column: 5 / span 3;
}

.gallery-contents li:nth-child(9n - 3) {
	grid-column: 8 / span 3;
	margin-right: 0;
}

.gallery-contents li:nth-child(9n - 2) {
	grid-column: 1 / span 4;
}

.gallery-contents li:nth-child(9n - 1) {
	grid-column: 5 / span 2;
}

.gallery-contents li:nth-child(9n) {
	grid-column: 7 / span 4;
	margin-right: 0;
}