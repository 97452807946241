.play-button {
	width: 32px;
	height: 32px;
	z-index: 100;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: transparent;
	border: none;
	border-radius: 100vw;
	background-image: url(../../assets/icons/video-play.png);
	background-size: cover;
	cursor: pointer;
}

.play-button img {
	width: 32px;
	height: 32px;
}

.gallery-video-wrapper,
.slideshow-video-wrapper {
	position: relative;
}

.slideshow-video-wrapper .play-button {
	top: 40px;
	left: 40px;
	pointer-events: none;
	opacity: 0.3;
}

.slideshow-video-wrapper.active .play-button {
	opacity: 1;
}

.gallery-video-wrapper.playing .play-button {
	display: none;
}

.gallery-video-wrapper.playing {
	cursor: pointer;
}
