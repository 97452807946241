.widget-credit-card-container {
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  border: solid 1px #d8e0e5;
  border-radius: 12px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
}

.widget-credit-card-container-active {
  border: solid 1px #29a7ff;
}

.widget-credit-card-image {
  max-height: 28px;
}

.widget-credit-card-type {
  line-height: normal;
  padding: 0;
  margin: 0;
}

.widget-credit-card-last-digits {
  line-height: normal;
  padding: 0;
  margin: 0;
}
