.widget-payment-input-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: solid 1px rgba(218, 219, 221, 0.64);
  background-color: #ffffff;
}

.widget-payment-input-root {
  display: flex;
  flex-direction: column;
  height: 44px;
}

.widget-payment-input-root__cvv {
  width: 102px;
}

p.widget-payment-input-error {
  margin-top: -2px;
  font-size: 12px;
  padding-left: 5px;
  color: red !important;
}

.widget-payment-input-icon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-payment-input {
  flex: 9;
}

.widget-payment-input #bluesnap-hosted-iframe-cvv {
  max-height: 40px;
}

input#ccn  {
  color: #fff !important;
}

.inputStyle {
  color: white!important;
}
