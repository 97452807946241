.invitaion-info__wrapper {
    position: relative;
    width: 349px;
    background-color: #fff;
    border-radius: 12px;
    text-align: center;
    padding: 23px 24px;

    > p {
        text-align: center;
        width: 246px;
        margin: auto;
        margin-top: 32px;
        font-family: "Poppins-SemiBold";
        opacity: 1;

        > span {
            font-family: "Poppins-Regular";
        }
    }

    > .info-text {
        font-size: 14px;
        font-family: "Poppins-Regular";
        margin-bottom: 40px;
    }

    > div:first-child {
        text-align: initial;
    }
}

.invitation-default-svg {
    width: 72px;
    height: 72px;
    margin: 16px 0;
}

.selected-date {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 20px;

    > .date {
        font-size: 16px;
        font-family: "Poppins-Medium";
    }

    > .time {
        color: rgba(92, 214, 190, 1);
        font-size: 14px;
    }
}

.selected-date.ended {

    > .date {
        text-decoration: line-through;
    }
    
    > .time {
        text-decoration: line-through;
        text-decoration-color: rgba(92, 214, 190, 1);
    }
}


.invitation-approve-btn{
    width: calc(100% - 40px);
    height: 44px;
    border-radius: 8px;
    background-color: rgba(0, 174, 220, 1);
    border: none;
    color: #fff;
    font-size: 14px;
    font-family: "Poppins-Medium";
    cursor: pointer;

}

.invitation-approve-btn:disabled {
    background-color: #dadbdd;
    cursor: unset;
}

.invitation-pay__wrapper {
    > p {
        font-size: 8px;
        text-align: center;
    }
    > p:first-of-type {
        margin-top: 32px;
    }
}

.invitation-payment-loading {
    filter: blur(5px);
}

.payment-loader {
    z-index: 1;
}

.meeting-already-ended-text {
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
    color: #F32B38;
    margin-top: 32px;
}