
* {
  box-sizing: border-box;
}

p {
  font-family: "Poppins-Regular";
}

.profile-wrapper {
  background-color: #f2f2f3;
  box-sizing: border-box;
}

.profile-wrapper-meeting-login .main-container  .main-container-wrapper  {
  background-image: url('../../assets/images/blurred-background-no-beta.png');
}


.content-wrapper {
  width: 100%;
  max-width: 1209px;
  margin: 0 auto;
  display: flex;
}

.content-wrapper-admin-page .nav-panel{
  display: none;
}

.content-wrapper-admin-page .btn-login{
  display: none;
}

.btn {
  background: #fff;
  border: 1px solid #00aedc;
  padding: 9px 29px 10px 30px;
  border-radius: 4px;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  color: #190e2f;
  letter-spacing: 0;
  cursor: pointer;
}

.btn:disabled {
  background-color: #f0f1f1;
  color: rgba(25, 14, 47, 0.3);
  border-color: #f0f1f1;
  pointer-events: none;
}

.top-bar {
  position: relative;
  background: #fff;
  padding: 14px 0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 255, 0.1);
  min-height: 64px;
  margin-bottom: 29px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.top-bar .dropdown-wrapper {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar > .content-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-bar > .content-wrapper > .logo-container {
  cursor: pointer;
}

.top-bar > .content-wrapper > .btn-login > span {
  color: rgba(0, 0, 0, 0.72);
  font-size: 16px;
  letter-spacing: 0;
  font-family: "Poppins-Medium";
}

.top-bar > .content-wrapper > .btn-login > button {
  margin-left: 22px;
}
.top-bar > .content-wrapper > .btn-login > button:hover{
  background-color: #f6f9fa;
}

main > .content-wrapper {
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

main > .content-wrapper > .sections {
  flex: 2.04;
  margin-right: 16px;
}

main > .content-wrapper > .sections > section {
  padding: 24px 32px 32px;
  border-radius: 4px;
  border: solid 1px rgba(218, 219, 221, 0.4);
  background-color: #ffffff;
  margin-bottom: 16px;
}

main > .content-wrapper > .sections > section h3 {
  font-size: 22px;
  font-family: "Poppins-Medium";
  letter-spacing: 0;
  color: #190e2f;
  opacity: 0.9;
  margin-bottom: 16px;
  line-height: 20px;
}

main > .content-wrapper > .sections > section h3.section-title {
  font-size: 16px;
}

main > .content-wrapper > .sections > section p {
  color: #000;
  font-size: 14px;
  font-family: "Poppins-Regular";
  opacity: 0.8;
  margin: 0;
  white-space: pre-line;
}

main > .content-wrapper > .sections > section.about {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 39px 32px 32px 39px;
}

main > .content-wrapper > .sections > section.about > .owner {
  flex: 0.8 1;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main > .content-wrapper > .sections > section.about > .owner > .image-wrapper {
  width: 150px;
  height: 150px;
  background-color: #f3f8f9;
  border-radius: 50%;
  overflow: hidden;
  border: 12.7px solid #f3f8f9;
}

main
  > .content-wrapper
  > .sections
  > section.about
  > .owner
  > .image-wrapper
  > img,
main
  > .content-wrapper
  > .sections
  > section.about
  > .owner
  > .image-wrapper
  > svg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

main
  > .content-wrapper
  > .sections
  > section.about
  > .owner
  > .image-wrapper
  > img::selection {
  background: transparent;
}

main
  > .content-wrapper
  > .sections
  > section.about
  > .owner
  > .info-wrapper
  > .social-links {
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

main
  > .content-wrapper
  > .sections
  > section.about
  > .owner
  > .info-wrapper
  > .btn {
  margin-top: 24px;
}
main
> .content-wrapper
> .sections
> section.about
> .owner
> .info-wrapper
> .btn:hover{
  background-color: #f6f9fa;
}

main
  > .content-wrapper
  > .sections
  > section.about
  > .owner
  > .info-wrapper
  > .social-links
  > li {
  margin-left: 4px;
  margin-right: 4px;
}

main > .content-wrapper > .sections > section.about > .info {
  flex: 3 1;
  position: relative;
}

.info-action {
  position: absolute;
  cursor: pointer;
  color: #6A6E75;
  font-weight: 600;
}

.info-action > p {
  font-family: "Poppins-SemiBold" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: #000000;
  opacity: 1 !important;
}

.info-action.show-less{
  bottom: -25px;
}

main > .content-wrapper > .sidebar {
  flex: 1;
}

main > .content-wrapper > .sidebar .sidebar-box {
  background: #fff;
  border: solid 1px rgba(218, 219, 221, 0.4);
  border-radius: 4px;
}

main > .content-wrapper > .sidebar .sidebar-box > .title-container {
  padding: 31px 32px 22px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

main > .content-wrapper > .sidebar .sidebar-box > .title-container > .box-icon {
  width: 76px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
main
  > .content-wrapper
  > .sidebar
  .sidebar-box
  > .title-container
  > .box-icon
  > img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

main
  > .content-wrapper
  > .sidebar
  .sidebar-box
  > .title-container
  > .box-title {
  font-size: 18px;
  letter-spacing: 0;
  color: #190e2f;
  font-family: "Poppins-SemiBold";
  line-height: normal;
  margin-left: 21px;
}

main > .content-wrapper > .sidebar .sidebar-box > .box-divider {
  display: block;
  width: 100%;
  height: 1px;
  opacity: 0.4;
  background-color: #dadbdd;
}

main > .content-wrapper > .sidebar .sidebar-box > .box-content {
  padding: 24px 32px 32px;
}

main
  > .content-wrapper
  > .sidebar
  .sidebar-box
  > .box-content
  > .services-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 350px);
  padding: 0px 2px;
}

main > .content-wrapper > .sidebar .sidebar-box > .box-content > h3 {
  font-weight: normal;
  font-size: 18px;
  font-family: "Poppins-Medium";
  letter-spacing: 0;
  color: #190e2f;
  text-align: left;
  text-transform: unset;
  margin: 0px 0px 16px;
}

main
  > .content-wrapper
  > .sidebar
  .sidebar-box
  > .box-content
  > .services-wrapper
  > .single-service:first-child {
  margin-top: 0;
}

main > .content-wrapper > .sidebar .sticky {
  max-height: 100vh;
}

.single-service {
  width: 100%;
  min-height: 118px;
  border-radius: 8px;
  border: 2px solid #F9F9F9;
  background-color: #ffffff;
  margin-top: 15px;
  margin-bottom: 1px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.auth-dropdown ul{
  padding: 0;
}
.auth-dropdown .MuiMenuItem-root{
  min-height: unset;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 8px;
}

.service-popover{
  pointer-events: none;
}
.service-popover  .MuiPopover-paper {
  overflow-y: inherit;
  overflow-x: inherit;
}
.popover-content{
  border-radius: 5px;
  border: solid 1px rgba(155, 158, 163, 0.4);
  padding: 5px 6px;
  position: relative;

}
.popover-content-arrow-border, .popover-content-arrow {
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px;
  transform: translate(-50%, 0);
}

.popover-content-arrow-border {
  top: 37px;
  border-color: rgba(155, 158, 163, 0.4) transparent transparent transparent;
}

.popover-content-arrow {
  top: 36px;
  border-color: white transparent transparent transparent;
}

.single-service.chosen-service {
  min-height: 90px;
  margin-bottom: 12px;
  cursor: default;
}

.single-service.chosen-service > .info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-service.chosen-service > .info > .service-title {
  font-size: 14px;
}

.single-service.chosen-service > .details > .duration {
  width: 36px;
  height: 36px;
}

.single-service.chosen-service > .details > .price {
  margin-top: 2px;
}

.single-service.is-new {
  border-color: #FF707A;
}
.single-service.selected {
  border-color: #00aedc;
}

.single-service.selected > .details {
  border-right-color: #00aedc;
}

.single-service > .details {
  flex: 1 1;
  background: #f9f9fa;
  border-right: 1px solid #e9eaeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-service > .details > .duration {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-service > .details > .duration > span:first-child {
  color: #fff;
  letter-spacing: 0.32px;
  line-height: 21px;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}

.single-service > .details > .duration > span:last-child {
  color: #fff;
  letter-spacing: 0px;
  font-size: 11px;
  line-height: 16px;
  margin-top: -5px;
  font-family: 'Poppins-Regular';
}
.service__price--icon{
  display: flex;
  width: 100%;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}


.single-service > .details > .service__price--icon > .price {
  font-size: 12px;
  line-height: 21px;
  padding-bottom: 8px;
  color: #190e2f;
  margin-left: 5px;
  font-family: "Poppins-Medium";
}

.single-service > .info {
  flex: 2.7 1;
  background: #fff;
  padding: 12px;
  transition: all 1s ease-out;
}

.single-service > .info > .service-title {
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #190e2f;
  line-height: 21px;
  letter-spacing: 0;
  margin-bottom: 5px;
  width: calc(100% - 20px);
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
.booking-popup:focus-visible{
  outline: none;
}
.booking-popup .single-service > .info > .service-title{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 1rem ;
}

.single-service > .info > .service-description {
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #474f5a;
  letter-spacing: 0;
  line-height: normal;
  word-break: break-word;
  white-space: pre-line;
  max-height: 85px;
  transition: all 0.5s ease;
}
.single-service > .info >  .service-description.expanded {
  max-height: 1000px;
}


@media screen and (max-width: 900px) {
  .single-service > .info > .service-description {
    font-size: 12px;
    color: #474F5A;
  }
}

.single-service > .info > .service-description .show-more {
  font-family: "Poppins-SemiBold";
  font-size: 12px;
  color: #000;
  opacity: 0.9;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  text-decoration: underline;
}

.single-service > .info > .service-description .show-more:focus {
  outline: 0px;
}

.single-service > .service-circle {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
}
.single-service > .service-circle > .service-checked {
  border: 1px solid #dadbdd;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.single-service > .service-circle > .service-checked > .checkmark {
  visibility: hidden;
  width: 15px;
  height: 5px;
  border-left: 2px solid #00aedc;
  border-bottom: 2px solid #00aedc;
  transform: rotate(-45deg);
  z-index: 10;
  position: absolute;
  bottom: 9px;
  left: 4px;
}

.single-service.selected > .service-circle > .service-checked > .checkmark {
  visibility: visible;
}

.single-service.selected > .service-circle > .service-checked {
  display: flex;
  width: 100%;
  height: 100%;
  border: 2px solid #00aedc;
  border-radius: 20px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.single-service.selected > .service-circle > .service-checked:after {
  display: block;
  content: "";
  position: absolute;
  top: 1px;
  right: -2px;
  width: 7px;
  height: 5px;
  background: #fff;
  transform: rotate(45deg);
}

.btn-booking-container {
  margin-top: 24px;
}

.btn-booking {
  border-radius: 8px;
  width: 100%;
  background-color: #00aedc;
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #fff;
}
.btn-booking:hover{
  background-color: #00a1cc;
}

.row {
  border-right: 4px solid var(--gray-1);
  padding-bottom: 30px;
}

.row:last-of-type {
  border-right: none;
}

.row:nth-of-type(2) > div,
.row:nth-of-type(2) > form,
.row:nth-of-type(3) {
  padding: 25px;
  box-sizing: border-box;
}

.row:nth-of-type(3) {
  border-right: none;
  border-bottom: 0;
}

.row:nth-of-type(2) .profile-gallery {
  border-top: 4px solid var(--gray-1);
  border-bottom: 4px solid var(--gray-1);
}

.row:nth-of-type(2) > div:last-of-type,
.row:nth-of-type(2) > form {
  border-bottom: none;
}

.row:nth-of-type(2) > form {
  padding-top: 0;
}

.row:nth-of-type(3),
.row:nth-of-type(4) {
  padding: 25px;
  box-sizing: border-box;
}

.btn-1 {
  font-size: 16px;
}

.row:nth-of-type(3) .btn-1,
.row:nth-of-type(1) .btn-1 {
  display: block;
  width: 300px;
  height: 56px;
  padding: 16px 40px;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
}

.message-button {
  background-color: var(--profile-secondary-color);
  background-image: var(--profile-secondary-color);
}

.message-button-disabled {
  pointer-events: none;
}

.message-button span {
  color: var(--profile-secondary-text-color) !important;
}

.row:nth-of-type(1) .btn-1:nth-of-type(1) {
  margin-top: 0;
}

.row:nth-of-type(3) .btn-1 svg,
.row:nth-of-type(1) .btn-1 svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.row:nth-of-type(4) {
  display: none;
}

.row:nth-of-type(1) .mobile-buttons {
  width: 100%;
  display: none;
}

h2,
h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: rgba(8, 40, 62, 0.9);
  text-transform: uppercase;
}

h3 {
  text-transform: capitalize;
  font-size: 16px;
  font-size: 16px;
  font-weight: 500;
  color: var(--blue-2-08);
}

p {
  opacity: 0.8;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: var(--blue-2);
}

:root {
  --white: #fff;
  --gray-1: #f4f5f6;
  --gray-2: #eff3f5;
  --blue-1: #137bdc;
  --blue-2: #0a283d;
  --blue-2-08: #0a283dcc;
  --blue-3: #3a5365;
  --green-1: #25e08d;
  --gray-blue: #0f3c5c;
  --light-gray: #9fb1be;
  --thin-gray: #d8e0e5;
  --thin-gray-2: #f4f5f6;
  --cyan: #00a1ff;
  --cyan-2: #2596e5;
  --gray-3: #f8f9fa;
}

:root .profile-wrapper_dark {
  --white: #1d3e54;
  --gray-1: #0b2c42;
  --gray-3: #30536d;
  --blue-2: #ffffff;
  --blue-2-08: #fff;
  --gray-blue: #fff;
  --gray-2: #eff3f5;
  --blue-1: #137bdc;
  --blue-3: #3a5365;
  --green-1: #25e08d;
  --light-gray: #9fb1be;
  --thin-gray: #d8e0e5;
  --thin-gray-2: #0a283d;
  --cyan: #00a1ff;
  --cyan-2: #2596e5;
}

.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}

button {
  outline: none;
}

.widget-registration-texfield-container .MuiFormControl-root .Mui-error {
  color: #f44336 !important;
  font-style: italic;
}

.display-mobile {
  display: none;
}

@media screen and (max-width: 1209px) {
  .top-bar {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 900px) {
  .display-desktop {
    display: none;
  }
  .display-mobile {
    display: block;
  }
  .display-owner-name {
    display: block;
    font-size: 24px;
    color: #190E2F;
    font-family: 'Poppins-Bold' !important;
    margin-top: 24px;
    opacity: 1 !important;
  }

  .top-bar {
    margin-bottom: 16px;
  }

  .info-wrapper {
    margin-right: 15px;
    border: 2px solid #F0F1F1;
    width: 99%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-left: none;
    margin-left: 85px;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack {
    overflow-y: visible !important;
  }

  main 
  > .content-wrapper 
  > .sections 
  > section.about 
  > .info {
    width: 100%;
  }

  main 
  > .content-wrapper 
  > .sections 
  > section.about 
  > .info p{
    color: #6A6E75;
  }

  .DayPickerInput-OverlayWrapper .DayPickerInput-Overlay {
    top: 0px;
  }

  main > .content-wrapper > .sections {
    margin: 0 3px;
    display: flex;
    flex-direction: column;
    background: white;
  }

  main > .content-wrapper > .sections > section {
    margin-bottom: 4px;
    padding: 24px;
    order: 3;
  }

  main > .content-wrapper > .sections > .services {
    border-top: none;
  }

  main > .content-wrapper > .sections > .service-name {
    padding: 16px 10px;
    order: 2;
    margin: 0;
    box-shadow: 0px 16px 32px rgba(120, 139, 255, 0.2);
    border-radius: 100px;
    position: relative;
  }
  main > .content-wrapper > .sections > .service-name > .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  main
    > .content-wrapper
    > .sections
    > .service-name
    > .title-container
    > .box-icon {
    margin-right: 16px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }

  main
    > .content-wrapper
    > .sections
    > .service-name
    > .title-container
    > .box-icon
    > img {
    width: 100%;
    height: 100%;
  }

  main
    > .content-wrapper
    > .sections
    > .service-name
    > .title-container
    > .box-title {
    font-size: 16px;
    font-family: "Poppins-Medium";
    font-weight: 600;
    letter-spacing: 0;
    color: #6A6E75;
  }

  main
  > .content-wrapper
  > .sections
  > section.about
  > .owner
  > .info-wrapper
  > .btn {
    margin-top: 0px;
    padding: 7px 13px;
    background: #00AEDC;
    border: 1px solid #00AEDC;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 14px;
    width: 144px;
    margin-bottom: 16px;
    margin-left: 20px;
  }

  main > .content-wrapper > .sections > section.about {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
    order: 1;
    margin-bottom: 0px;
    border-bottom: none;
  }

  main
    > .content-wrapper
    > .sections
    > section.about
    > .owner
    > .image-wrapper {
      width: 121px;
      height: 121px;
      border: none;
      position: absolute;
  }

  main > .content-wrapper > .sections > section.about > .owner {
    margin: 0;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  main
    > .content-wrapper
    > .sections
    > section.about
    > .owner
    > .info-wrapper
    > .social-links {
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 20px;
    height: 32px;
  }

  main
    > .content-wrapper
    > .sections
    > section.about
    > .owner
    > .info-wrapper
    > h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .nav-panel .my-avatar-container {
    margin-right: 10px;
  }

  

  main .single-service > .info > .service-description .show-more {
    border-left: 2px solid #9b9ea3;
    border-bottom: 2px solid #9b9ea3;
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: transparent;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    z-index: 10000;
  }

  main .single-service > .info > .service-description .show-more.opened {
    transform: rotate(135deg);
  }

  .single-service {
    margin-top: 8px;
  }

  .btn-booking {
    margin-top: 16px;
  }

  .btn-booking-container {
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0px 28px;
    z-index: 101;
    
  }
}

@media screen and (max-width: 450px) {
  main
    > .content-wrapper
    > .sections
    > section.about
    > .owner
  .btn-booking-container {
   position: unset;
  }
  .btn-booking-container {
    margin: 0;
  }

  main > .content-wrapper > .sections > section.about > .owner {
    justify-content: space-between;
  }

  main > .content-wrapper > .sections > .service-name > .title-container {
    margin-left: 35px;
  }

  main
    > .content-wrapper
    > .sections
    > .service-name
    > .title-container
    > .box-icon {
    min-width: 48px;
  }
}

@media screen and (max-height: 600px) {
  .profile-flow-stack .cancel-next-message {
    position: static !important;
    padding: 0 !important;
    bottom: unset !important;
    left: unset !important;
    margin-top: 16px;
  }
  .active-step-3 .profile-flow-stack .cancel-next-message {
    margin-top: 0;
  }
  .active-step-1 .profile-flow-stack > form,
  .active-step-1 .profile-flow-stack > form > .widget-signup-inner-bottom {
    height: auto !important;
  }
}


@media screen and (min-height: 600px) {
  .active-step-0 .profile-flow-stack {
    overflow-y: visible !important;
  }
}

/* Booking Popup */

.booking-popup-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-popup-wrapper > .booking-popup {
  width: 351px;
  height:auto;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
}

.booking-popup-wrapper > .booking-popup > .booking-header {
  height: 62px;
  background-color: #f3f8f9;
  position: relative;
  border-top-left-radius:12px ;
  border-top-right-radius:12px ;
}

.booking-popup-wrapper > .booking-popup > .booking-info-header {
  background-color: transparent;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-header
  > .title-wrapper
  > .popup-icon {
  position: absolute;
  top: -5px;
  left: 15px;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-header
  > .title-wrapper
  > .close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-info-header
  > .title-wrapper
  > .close-icon {
  top: 20px;
}


.booking-popup-wrapper
  > .booking-popup
  > .booking-header
  > .title-wrapper
  > .title {
  display: block;
  width: 100%;
  height: 100%;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  color: #190e2f;
  padding: 19px 0 0 31px;
}


.booking-popup-wrapper > .booking-popup > .booking-footer {
  padding: 0px 32px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.booking-popup-wrapper > .booking-popup > .booking-content {
  padding: 0 0 15px;
  height: calc(100% - 62px);
}

.booking-popup-wrapper > .booking-popup > .booking-content > .selected-data {
  padding: 0 20px 12px;
  border-bottom: 1px solid rgba(155, 158, 163, 0.2);
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .selected-data
  > .selected-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .selected-data
  > .selected-date
  > .date {
  font-size: 12px;
  line-height: 27px;
  color: #190e2f;
  margin-right: 8px;
  font-family: "Poppins-Medium";
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .selected-data
  > .selected-date
  > .time {
  font-family: "Poppins-Medium";
  font-size: 12px;
  line-height: 24px;
  color: #6a6e75;
  margin-top: 0;
}
.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .selected-data
  > .selected-date
  > .time-mobile {
  font-size: 12px;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack {
  padding: 0px 20px 0px;
  height: calc(100% - 160px);
  position: relative;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content.active-step-0
  > .profile-flow-stack {
  height: calc(100% - 139px);
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > h3 {
  color: #190e2f;
  font-size: 14px;
  font-family: "Poppins-Regular";
  opacity: 0.64;
  margin-top: 20px;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > .input-label {
  color: rgba(0, 0, 0, 0.56);
  font-size: 14px;
  font-family: "Poppins-Medium";
  padding-left: 5px;
  margin-bottom: 4px;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > .envite-input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: solid 1px rgba(218, 219, 221, 0.64);
  background: #fff;
  position: relative;
  margin-bottom: 14px;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > .envite-input.calendar.has-error {
  border-color: #fc707a;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > .envite-input
  > .right-icon {
  position: absolute;
  top: 11px;
  right: 18px;
  pointer-events: none;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > .envite-input.calendar
  > .DayPickerInput {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > .envite-input.calendar
  input {
  width: 100%;
  height: 100%;
  border: 0px solid #fff;
  padding: 0px 0px 0px 20px;
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  cursor: pointer;
}
.booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .profile-flow-stack
  > .envite-input.calendar
  input::placeholder {
  color: rgba(0, 0, 0, 0.32);
}

.booking-popup .password-eye.active > path{
  stroke: #00aedc !important;
}

.booking-popup .single-service.chosen-service{
  max-height: 90px;
}

.booking-popup .single-service.chosen-service > .info{
  align-items: center;
}

.booking-popup .active-step-3 .widget-payment-container .cancel-next-message{
  bottom: 30px;
}

.profile-flow-stack > .time-picker-container {
  position: relative;
  margin-bottom: 14px;
}

.envite__selectInput .select__value-container,
.envite__selectInput .select__indicators{
  cursor: pointer;
}

.profile-flow-stack > .time-picker-container > .calendar-loader {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.profile-flow-stack .cancel-next-message {
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
}

#booking-forgot-password .cancel-next-message {
  margin-top: 130px;
}


.profile-flow-stack .cancel-next-message > button {
  padding: 12px 52px;
  border-radius: 4px;
  background-color: #00aedc;
  font-family: "Poppins-Medium";
  color: #fff;
  font-size: 14px;
  height: 44px;
  margin-top: 10px;
  border: none;
  display: flex;
  justify-content: center;
  width: 49%;
}
.profile-flow-stack .cancel-next-message > button:hover {
  background-color: #00a1cc;
}

.active-step-1 .profile-flow-stack .cancel-next-message > button {
  padding: 12px 40px;
}

.profile-flow-stack .cancel-next-message > button:disabled {
  background-color: #f0f1f1;
  color: rgba(25, 14, 47, 0.3);
}

.active-step-0 .back-btn {
  display: none;
}

.active-step-0 .selected-date {
  display: none !important;
}

.next-btn > .MuiCircularProgress-colorPrimary {
  width: 20px !important;
  height: 20px !important;
}

.DayPickerInput-Overlay {
  top: 11px;
  left: -3px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px #dadbdd;
}

.booking-content.summary-step h3 {
  font-size: 20px;
  font-family: "Poppins-SemiBold";
  color: #190e2f;
  text-align: center;
  margin: 0;
  letter-spacing: 0.4px;
  line-height: 36px;
  margin-left: 8px;
}

.booking-content.summary-step .image-wrapper {
  width: 60px;
  height: 60px;
  border: 6px solid #f3f8f9;
  border-radius: 30px;
  overflow: hidden;
  margin: 16px auto;
}

.booking-content.summary-step .image-wrapper > img,
.booking-content.summary-step .image-wrapper > svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.booking-content.summary-step .owner-name {
  margin-bottom: 16px;
  color: #190e2f;
  font-family: "Poppins-Medium";
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
}

.booking-content.summary-step > .selected-data {
  border-bottom: 0px solid #fff !important;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content.summary-step
  > .selected-data
  > .selected-date {
  flex-direction: column;
  margin-top: 20px;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content.summary-step
  > .selected-data
  > .selected-date
  > .date {
  font-size: 16px;
  text-align: center;
  margin-bottom: 4px;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content.summary-step
  > .selected-data
  > .selected-date
  > .time {
  color: #5cd6be;
  font-size: 14px;
  text-align: center;
}

.booking-popup-wrapper
  > .booking-popup
  > .booking-content.summary-step
  > .profile-flow-stack {
  height: auto;
  margin-top: 20px;
}

#sign-up-form .widget-signup-inner-bottom .widget-sign-up-or {
  margin: 6px 0;
}

#sign-up-form
  .widget-registration-texfield-container
  .widget-registration-input:last-child {
  margin-bottom: 0px;
}
#sign-up-form
  .widget-registration-texfield-container
  .widget-registration-input
  p {
   height: 7px;
}
#sign-up-form .widget-signup-already-have-account-box {
  margin: 7px 0px;
}

@media screen and (max-width: 500px) {
  .booking-popup-wrapper > .booking-popup {
    width: 97.5%;
  }

  .booking-popup-wrapper
  > .booking-popup
  > .booking-header
  > .title-wrapper
  > .title {
    text-align: center;
    padding-left:0;

  }

  .booking-popup-wrapper
  > .booking-popup
  > .booking-content
  > .selected-data
  > .selected-date {
  justify-content: space-between !important;
}
}

/* Custom Scroll */
.custom-scroll::-webkit-scrollbar {
  width: 2px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 1.2px;
  border: 4px solid #dadbdd;
}

.custom-scroll::-webkit-scrollbar:horizontal {
  height: 2px;
}
.custom-scroll::-webkit-scrollbar-track:horizontal {
  background: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb:horizontal {
  border-radius: 1.2px;
  border: 4px solid #dadbdd;
}
/* End Custom Scroll */

/* React Select */

.select__option {
  color: #6a6e75 !important;
  font-size: 14px !important;
  font-family: "Poppins-Regular";
}

.select__option.select__option--is-disabled {
  color: rgba(25, 14, 47, 0.32) !important;
}

.select__option.customized {
  background-color: transparent;
  cursor: pointer;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select__option.customized > img,
.select__single-value.customized > img {
  margin-right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
}

.select__option.select__option--is-focused {
  background-color: #f3f3f3 !important;
}

.select__single-value.customized {
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select__single-value {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: rgba(0, 0, 0, 0.8) !important;
  opacity: 1;
  margin: 0 0 12px;
}

.select__single-value:not(.customized) {
  padding-left: 12px;
}

/* End react select */

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  input,
  textarea,
  .MuiOutlinedInput-input,
  .MuiInputBase-input {
    font-size: 16px !important;
  }
}

.message-popup-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  background-color: rgba(28, 31, 36, 0.48);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.message-popup-wrapper > .message-popup {
  width: 359px;
  height: 499px;
  border-radius: 12px;
  box-shadow: 0 10px 48px 0 rgba(25, 14, 47, 0.08);
  border: solid 1px rgba(218, 219, 221, 0.8);
  background-color: #ffffff;
}

.message-popup-wrapper > .message-popup > .message-header {
  width: calc(100% + 2px);
  height: 48px;
  background-color: #00aedc;
  border: solid 1px #00aedc;
  left: -1px;
  top: -1px;
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.message-popup-wrapper > .message-popup > .message-header > .title {
  font-family: 'Poppins-Bold';
  font-size: 16px;
  color: #ffffff;
  margin-left: 32px;
}

.message-popup-wrapper > .message-popup > .message-header > .close-icon {
  right: 18px;
  top: 12px;
  position: absolute;
  cursor: pointer;
}

.message-popup > .message-header > .close-icon > svg > g > path {
  stroke: #ffffff;
}

.message-popup > .message-header > .close-icon > svg > g {
  opacity: 1;
}

.message-popup-wrapper > .message-popup > .message-content {
  padding: 24px;
  height: 377px; /*429px*/
}

.message-popup-wrapper > .message-popup > .message-content > .message-is-sent{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.message-popup-wrapper > .message-popup > .message-content > .message-is-sent > .icon-wrapper{
  margin-bottom: 18px;
}

.message-popup-wrapper > .message-popup > .message-content > .message-is-sent > .sent-title{
  font-size: 20px;
  font-family: 'Poppins-Bold';
  font-weight: bold;
}

.message-popup-wrapper > .message-popup > .message-content > .message-is-sent > .sent-text{
  color: rgba(0,0,0, 0.8);
  font-family: 'Poppins-Medium';
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  width: 260px;
}

.message-text {
  width: 100%;
}

.message-text
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
  height: 251px;
}

.send-message-button--wrapper,
.message-button {
  height: 44px;
  width: 142px;
}
.send-message-button--wrapper{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.send-message-button--wrapper svg {
  width: 25px;
}

.MuiFormControl-root.MuiTextField-root.message-text {
  margin-bottom: 24px;
}

.message-email-input {
  width: 100%;
  height: 44px;
  border: 1px solid #dadbdd;
  padding-left: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-radius: 8px;
  margin-bottom: 16px;
}

.message-email-input > .icon {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.text-field-icon-left {
  left: 20px;
}

.message-email-input > input {
  outline: none;
  width: 100%;
  height: 100%;
  font-family: Poppins-Regular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.72);
  border: none;
  border-radius: 8px;
  padding-right: 10px;
}

.message-email-input input::placeholder {
  font-size: 14px;
  font-family: "Poppins-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9ea3;
}

.icon svg {
  stroke: #b9d7e0;
}

.message-email-input > input:focus + .icon svg {
  stroke: #00aedc;
}

textarea.message-text {
  width: 100%;
  height: 251px;
  border-radius: 8px;
  font-family: Poppins-Regular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.72);
  outline: none;
  padding: 14px 15px 15px 20px;
  border: 1px solid #dadbdd;
  resize: none;
  margin-bottom: 24px;
}

textarea.message-text::placeholder {
  font-family: "Poppins-Regular";
  color: #dadbdd;
}

.send-message-button--wrapper button.message-button {
  border-radius: 8px;
  background-color: #dadbdd !important;
  border: none;
  font-family: Poppins-Medium;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.send-message-button--wrapper button.message-button.disabled {
  background-color: #00aedc !important;
  cursor: pointer;
}

.send-message-button--wrapper button.message-button .button-text {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .message-popup-wrapper > .message-popup {
    width: 359px;
    height: 536px;
  }

  textarea.message-text {
    margin-bottom: 77px;
    padding: 14px 16px 16px 16px;
  }

  .message-popup-wrapper > .message-popup > .message-header > .title {
    font-family: 'Poppins-Medium';
  }
}

@media screen and (max-width: 320px) {
  .message-popup-wrapper > .message-popup {
    width: 300px;
    height: 536px;
  }

  textarea.message-text {
    margin-bottom: 77px;
    padding: 14px 16px 16px 16px;
  }

  .message-popup-wrapper > .message-popup > .message-content {
    margin: 32px;
    width: auto;
    height: 429px;
  }

  .send-message-button--wrapper button.message-button .button-text {
    margin-left: 32px;
  }
}

.nav-panel {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-panel > .notifications {
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  margin-top: 5px;
}

.nav-panel > .notifications > .unread {
  width: 10px;
  height: 10px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px;
  overflow: hidden;
}

.nav-panel > .notifications > .unread:before {
  position: static;
  display: block;
  content: "";
  background-color: #09d77e;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.nav-panel > .dots {
  margin-top: 5px;
}

.nav-panel > .my-avatar-container > .my-avatar .user-avatar {
  width: 30px !important;
  height: 30px !important;
  border-radius: 100%;
}

.nav-panel .my-avatar-container {
  position: relative;
}

.nav-panel .my-avatar-container > .my-avatar {
  cursor: pointer;
}

.nav-panel .my-avatar-container > .arrow {
  position: absolute;
  top: 5px;
  right: -20px;
  cursor: pointer;
}

.nav-panel .my-avatar-container > .arrow.arrow-down {
  transform: rotate(180deg);
}

.nav-panel .my-avatar-container > .envite-dropdown-menu {
  min-width: 123px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dadbdd;
  background-color: #ffffff;
  position: absolute;
  top: 120%;
  right: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 8px 10px;
  z-index: 10;
}

.nav-panel .my-avatar-container > .envite-dropdown-menu > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nav-panel .my-avatar-container > .envite-dropdown-menu > ul > li {
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.nav-panel .my-avatar-container > .envite-dropdown-menu > ul > li > span {
  display: flex;
  align-items: center;
}
.nav-panel .my-avatar-container > .envite-dropdown-menu > ul > li > .label {
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #6a6e75;
}

.nav-panel .my-avatar-container > .envite-dropdown-menu > ul > li:hover {
  background-color: #f3f3f3;
}
.nav-panel
  .my-avatar-container
  > .envite-dropdown-menu
  > ul
  > li:hover
  > .label {
  color: rgba(0, 0, 0, 0.72);
  font-family: "Poppins-Medium";
}
.nav-panel
  .my-avatar-container
  > .envite-dropdown-menu
  > ul
  > li:hover
  > .icon
  path {
  stroke: #21aedc;
}

/* Login Styles */

.sign-up-live .MuiOutlinedInput-root {
  height: 48px;
  border-radius: 12px;
}

.sign-in__live--wrapper .MuiOutlinedInput-input {
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.sign-up-live .MuiInputBase-root {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0f3c5c !important;
}

.sign-up__live--header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sign-up_terms {
  flex-direction: column;
}

.sign-in__live--header > .close-icon,
.sign-up__live--header > .close-icon {
  position: absolute;
  right: 0;
  top: 5px;
  cursor: pointer;
}

.MuiOutlinedInput-root {
  position: relative;
}

.main-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  background-color: rgba(28, 31, 36, 0.48);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
}

.main-container > .main-container-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.sign-up__input--wrapper {
  font-size: 11px;
  top: -5px;
  line-height: 9px;
  margin-bottom: -5px;
}

.sign-up-live p {
  height: 5px;
}

.sign-up__error {
  color: red;
  margin-top: 5px;
}

.sign-up__live--wrapper,
.sign-in__live--wrapper {
  width: 344px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
  border-radius: 12px;
  z-index: 100000;
  padding: 22px 16px 16px;
  box-shadow: 0 10px 48px 0 rgba(25, 14, 47, 0.08);
  border: solid 1px rgba(218, 219, 221, 0.8);
}

.sign-up__live--wrapper {
  height: 570px;
}

.sign-up__live--wrapper > .sign-up__live--header,
.sign-in__live--wrapper > .sign-up__live--header,
.sign-up__live--wrapper > .sign-in__live--header,
.sign-in__live--wrapper > .sign-in__live--header {
  position: relative;
  margin-bottom: 31px;
}

.sign-up__live--wrapper .envite-logo-svg,
.sign-in__live--wrapper .envite-logo-svg {
  width: 89px;
  height: 40px;
  margin-top: -20px;
}

.sign-up__live--wrapper > .sign-up__live--header .sign-up__live--header-text,
.sign-in__live--wrapper > .sign-up__live--header .sign-up__live--header-text,
.sign-up__live--wrapper > .sign-in__live--header .sign-up__live--header-text,
.sign-in__live--wrapper > .sign-in__live--header .sign-up__live--header-text,
.sign-up__live--wrapper > .sign-up__live--header .sign-in__live--header-text,
.sign-in__live--wrapper > .sign-up__live--header .sign-in__live--header-text,
.sign-up__live--wrapper > .sign-in__live--header .sign-in__live--header-text,
.sign-in__live--wrapper > .sign-in__live--header .sign-in__live--header-text {
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  color: #190e2f;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.sign-up_terms .sign-up__live--header-text {
  margin-left: -10px;
}

.sign-up__live--wrapper .outlined-dense.sign-up-live,
.sign-in__live--wrapper .outlined-dense.sign-up-live {
  margin-top: 0px;
}

.sign-up-live__passsend {
  margin-top: 10px !important;
}
.sign-up__live--wrapper .MuiOutlinedInput-input,
.sign-in__live--wrapper .MuiOutlinedInput-input {
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .sign-up__live--wrapper input,
  .sign-in__live--wrapper input,
  .sign-up__live--wrapper .personal-inforrmation__input,
  .sign-in__live--wrapper .personal-inforrmation__input,
  .sign-up__live--wrapper .MuiOutlinedInput-input,
  .sign-in__live--wrapper .MuiOutlinedInput-input,
  .sign-up__live--wrapper .MuiInputBase-input,
  .sign-in__live--wrapper .MuiInputBase-input,
  .envite-input.calendar input,
  .widget-payment-input-container,
  #payment-cardholder{
    font-size: 16px !important;
  }
}
.sign-up__live--wrapper .text-field-icon-left,
.sign-in__live--wrapper .text-field-icon-left {
  stroke: #c9cacd;
  top: 12px;
}
.sign-up__live--wrapper .MuiOutlinedInput-notchedOutline,
.sign-in__live--wrapper .MuiOutlinedInput-notchedOutline {
  border-color: rgba(218, 219, 221, 0.64);
}
.sign-up__live--wrapper .outlined-dense:hover fieldset,
.sign-in__live--wrapper .outlined-dense:hover fieldset,
.sign-up__live--wrapper .MuiInputBase-root:hover fieldset,
.sign-in__live--wrapper .MuiInputBase-root:hover fieldset,
.sign-up__live--wrapper .sign-up__phone--number:hover fieldset,
.sign-in__live--wrapper .sign-up__phone--number:hover fieldset {
  border: 1px solid rgba(218, 219, 221, 0.64) !important;
}
.sign-up__live--wrapper
  .sign-up-live
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.sign-in__live--wrapper
  .sign-up-live
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(218, 219, 221, 0.64) !important;
}
.sign-up__live--wrapper
  .sign-up-live
  .MuiOutlinedInput-root.Mui-focused
  .text-field-icon-left,
.sign-in__live--wrapper
  .sign-up-live
  .MuiOutlinedInput-root.Mui-focused
  .text-field-icon-left {
  stroke: #7fd4f0 !important;
}
.sign-up__live--wrapper .MuiOutlinedInput-root,
.sign-in__live--wrapper .MuiOutlinedInput-root {
  height: 44px !important;
}
.sign-up__live--wrapper .signup-live__mail,
.sign-in__live--wrapper .signup-live__mail {
  top: 15px;
}
.sign-up__live--wrapper .MuiIconButton-root,
.sign-in__live--wrapper .MuiIconButton-root {
  color: #e7e7e8;
  padding-right: 0px;
}
.sign-up__live--wrapper .MuiIconButton-root:hover,
.sign-in__live--wrapper .MuiIconButton-root:hover {
  background-color: transparent;
}
.sign-up__live--wrapper .password-is-visible .MuiIconButton-root path,
.sign-in__live--wrapper .password-is-visible .MuiIconButton-root path {
  stroke: #7fd4f0;
}
.sign-up__live--wrapper p.text,
.sign-in__live--wrapper p.text {
  font-size: 12px;
  font-family: "Poppins-Regular";
  color: rgba(25, 14, 47, 0.64);
  line-height: 21px;
  margin: 11px 0 18px;
  letter-spacing: 0;
  text-align: center;
}
.sign-up__live--wrapper p.text span.span,
.sign-in__live--wrapper p.text span.span,
.sign-up__live--wrapper p.text button.span,
.sign-in__live--wrapper p.text button.span {
  font-family: "Poppins-Regular";
  font-size: 11px;
  color: rgba(106, 110, 117, 0.5);
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}

.sign-up__live--wrapper p.text button:hover,
.sign-in__live--wrapper p.text button:hover {
  text-decoration: underline;
}

.sign-up__live--wrapper p.text span.span.underline,
.sign-in__live--wrapper p.text span.span.underline,
.sign-up__live--wrapper p.text button.span.underline,
.sign-in__live--wrapper p.text button.span.underline {
  text-decoration: underline;
  font-family: "Poppins-Regular";
  color: rgba(25, 14, 47, 0.64);
}
.sign-up__live--wrapper p.text.account-text,
.sign-in__live--wrapper p.text.account-text {
  margin: 10px 0px 0px;
}

.text a {
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}

.sign-up__live--wrapper p.text.account-text > span,
.sign-in__live--wrapper p.text.account-text > span,
.sign-up__live--wrapper p.text.account-text > button,
.sign-in__live--wrapper p.text.account-text > button {
  color: rgba(25, 14, 47, 0.72);
  cursor: pointer;
  font-family: "Poppins-Medium";
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 12px;
}

 .terms-and-conditions{
  height: calc(100% - 45px);
  overflow-y: scroll;
  max-height: 518px;
}
 .terms-and-conditions::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 4px;
}
 .terms-and-conditions::-webkit-scrollbar-thumb{
  border-radius: 1.8px;
  background-color: #d0d8dd;
}
 .terms-and-conditions p,
 .terms-and-conditions h3,
 .terms-and-conditions h4,
 .terms-and-conditions li{
  color: #6a6e75;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  font-family: "Poppins-Medium";
  letter-spacing: normal;
  margin: 0;
  margin-bottom: 1rem;
  text-align: left;
  opacity: 1;
}
 .terms-and-conditions h3,
 .terms-and-conditions h4{
  font-family: "Poppins-Bold";
}
 .terms-and-conditions h1{
  font-family: 'Poppins-Medium';
  font-size: 20px;
  text-align: center;
}

 .terms-and-conditions h2 {
  font-family: 'Poppins-Regular';
  font-size: 14px;
}

 .terms-and-conditions b{
  font-weight: 900;
  font-family: "Poppins-Medium";
  opacity: 1;
}
.sign-up__live--wrapper .btn-sign-up,
.sign-in__live--wrapper .btn-sign-up,
.sign-up__live--wrapper .btn-log-in,
.sign-in__live--wrapper .btn-log-in {
  background-color: #f77881;
  text-align: center;
  text-transform: unset;
  padding: 10px 0px;
  font-size: 14px;
  font-family: "Poppins-Medium";
  color: #fff;
  width: 100%;
  border-radius: 8px;
  height: 44px;
}
.sign-up__live--wrapper .btn-sign-up:hover,
.sign-in__live--wrapper .btn-sign-up:hover,
.sign-up__live--wrapper .btn-log-in:hover,
.sign-in__live--wrapper .btn-log-in:hover {
  background-color: #ff5c67;
}
.sign-up__live--wrapper div.sign-up-with,
.sign-in__live--wrapper div.sign-up-with,
.sign-up__live--wrapper div.log-in-with,
.sign-in__live--wrapper div.log-in-with {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 1px;
  background-color: #e7e8e9;
  margin-top: 31px;
}
.sign-up__live--wrapper div.sign-up-with > span,
.sign-in__live--wrapper div.sign-up-with > span,
.sign-up__live--wrapper div.log-in-with > span,
.sign-in__live--wrapper div.log-in-with > span {
  color: rgba(106, 110, 117, 0.5);
  background-color: #fff;
  padding: 0 12px;
  font-family: "Poppins-Regular";
  font-size: 11px;
  position: relative;
  /* top: -4px; */
}
.sign-up__live--wrapper .sc-container,
.sign-in__live--wrapper .sc-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
.widget-signup-inner-bottom .sc-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.sign-up__live--wrapper .sc-container > .sc-button,
.sign-in__live--wrapper .sc-container > .sc-button {
  border-radius: 8px;
}

.sign-up__live--wrapper .sc-container > .sc-button:hover,
.sign-in__live--wrapper .sc-container > .sc-button:hover,
.widget-signup-inner-bottom .sc-container > .sc-button:hover {
  background-color: transparent;
}


.sign-up__live--wrapper .sc-container .fb-login,
.sign-in__live--wrapper .sc-container .fb-login,
.widget-signup-inner-bottom  .fb-login{
  background-color:  #1877F2 !important;
  width: 198px;
  height: 40px;
  color: white;
  text-transform: initial !important;
}
.google-sign-in-image{
  width: 201px;
}
.sign-up__live--wrapper .sc-container .google-login,
.sign-in__live--wrapper .sc-container .google-login,
.widget-signup-inner-bottom  .google-login {
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  text-transform: initial !important;
  font-family: "Roboto";
  color: rgba(0, 0, 0, 0.5);
}

.sign-up__live--wrapper .sc-container .google-login svg,
.sign-in__live--wrapper .sc-container .google-login svg,
.widget-signup-inner-bottom  .google-login svg {
  margin-left: -15px;
  margin-right: 10px;
}

.widget-signup-inner-bottom  .fb-login {
  margin-top: 10px;
}

.reset-password-wrapper {
  width: 352px;
  height: 275px;
  padding: 18px 24px 32px 32px;
  box-shadow: 0 10px 48px 0 rgba(25, 14, 47, 0.08);
  border: solid 1px rgba(218, 219, 221, 0.8);
}
.reset-password-wrapper .btn-log-in {
  margin-top: 6px;
  background-color: #00aedc;
  height: 44px;
}
.reset-password-wrapper .btn-log-in:hover {
  background-color: #00aedc;
}
.reset-password-wrapper.password-is-sent {
  height: 257px;
}
.reset-password-wrapper > .sign-in__live--header {
  margin-bottom: 24px;
}
.reset-password-wrapper > .sign-in__live--header > .close-icon {
  position: absolute;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}
.reset-password-wrapper .btn-reset {
  background-color: #00aedc;
  margin-top: 7px;
}
.reset-pass-send {
  color: green;
  font-family: "Poppins-Regular";
  font-size: 14px;
  text-align: left;
  margin: -5px 0px 10px 5px;
  line-height: 22px;
}
.loader-view {
  width: 100%;
  position: absolute;
}
.reset-password-wrapper .btn-reset:hover {
  background-color: #00aedc;
}
.reset-password-wrapper .outlined-dense .sign-up-live__passsend {
  margin-top: 10px;
}
.reset-password-wrapper p.text {
  text-align: left;
  color: rgba(25, 14, 47, 0.8);
  font-size: 14px;
  margin-bottom: 24px;
  line-height: 22px;
}
.reset-password-wrapper .outlined-dense.sign-up-live {
  margin: 0 0 14px;
}
.reset-password-wrapper .text-field-icon-left {
  left: 10px;
}

.loader-wrapper svg circle {
  stroke: white;
}

/* Finish Login styles */

.booking-content-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-content-owner {
  opacity: 0.8;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #190e2f;
}
.booking-content-owner--name{
  font-family: 'Poppins-SemiBold';
}

.booking-content.summary-step .booking-content-image-wrapper {
  width: 71px;
  height: 71px;
  border: none;
}

.widget-main-summary__wrapper {
  margin-bottom: 20px !important;
}

.booking-popup-wrapper > .booking-popup > .booking-content > .profile-flow-stack > h3 {
  text-transform: none;
}

.widget-signup-title {
  font-family: 'Poppins-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #190e2f;
  text-transform: initial;
  margin-top: 20px;
}

#sign-up-form .widget-signup-title{
  font-family: 'Poppins-Medium';
}

.widget-signup-title span {
  font-family: 'Poppins-Bold';
}

.widget-signup-text {
  opacity: 0.4;
  font-family: 'Poppins-Medium';
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #190e2f;
  text-transform: initial;
  margin-top: -10px;
}

.widget-registration-texfield-container label {
  font-family: 'Poppins-Medium';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.56);
  margin-left: 5px;
}

.booking-popup-wrapper .profile-flow-stack  #sign-up-form .cancel-next-message,  
.booking-popup-wrapper .profile-flow-stack  #sign-in-form .cancel-next-message {
  bottom: unset;
  margin-top: 40px;
}

@media only screen and (max-height: 667px) { 
  .booking-popup-wrapper > .booking-popup > .booking-content > .selected-data {
    padding-bottom: 0px;
  }
  .booking-popup-wrapper > .booking-popup > .booking-content > .profile-flow-stack > h3 {
    margin-top: 5px;
  }

  .booking-popup-wrapper > .booking-popup > .booking-content > .profile-flow-stack > .envite-input {
    margin-bottom: 4px;
  }


}
@media screen and (max-height: 800px) {
  .widget-main-summary__wrapper {
    margin-bottom: 5px!important;
  }
  .widget-main-summary__wrapper .widget-summary-generated-link {
    margin-top: 15px;
  }
  .summary-step .profile-flow-stack {
    margin-top: 15px!important;
  }
  .booking-content.summary-step .image-wrapper {
    margin: 10px auto;
  }
  .summary-step .widget-summary-another-meeting {
    margin: 10px auto 10px auto;
  }

  .booking-popup-wrapper .profile-flow-stack  #sign-up-form .cancel-next-message,
  .booking-popup-wrapper .profile-flow-stack  #sign-in-form .cancel-next-message {
    margin-top: 0;
  }
  
  .booking-popup-wrapper .profile-flow-stack  #sign-up-form .widget-registration-input {
    margin: 2px auto;
  }

  #sign-up-form .widget-signup-title {
    margin: 10px 0;
  }
  #sign-up-form .widget-signup-text {
    margin-bottom: 5px;
  }
  #sign-up-form .widget-signup-inner-bottom .widget-sign-up-or {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  #sign-up-form .widget-signup-already-have-account-box {
    margin-top: 2px;
  }

  #sign-up-form .widget-signup-inner-bottom .fb-login {
    margin-top: 5px;
  }

  .widget-signup-already-have-account-box {
    margin-bottom: 2px;
  }

  #sign-in-form .widget-signup-title {
    margin: 12px auto;
    width: 250px;
  }
}

@media screen and (max-height: 740px) {
  .widget-main-summary__wrapper .widget-summary-link-title {
    line-height: 16px;
  }

  .booking-popup-wrapper > .booking-popup > .booking-content > .selected-data > .selected-date > .date {
    line-height: 20px;
  }

  .summary-step .booking-content-owner {
    margin: 8px auto;
  }

  .booking-popup-wrapper > .booking-popup > .booking-content > .selected-data {
    padding-bottom: 5px;
  }

  .booking-popup-wrapper > .booking-popup > .booking-content.summary-step > .selected-data > .selected-date {
    margin-top: 6px;
  }

  .summary-step .booking-content-owner {
    margin: 4px auto;
  }

  .profile-flow-stack .cancel-next-message > button {
    margin-top: 8px;
  }

  .widget-flow__wrapper .widget-payment-cards {
    margin-top: 10px;
  }
  
  .widget-flow__wrapper .Add-Payment-Details {
    margin-top: 10px;
  }

  .widget-flow__wrapper .widget-payment-fields-column {
    margin-top: 5px;
  }

  .active-step-3 .single-service.chosen-service {
    margin-bottom: 6px;
  }
}

.menu-item-action {
  font-family: 'Poppins-Regular' !important;
  font-size: 16px !important;
}

@media screen and (max-width: 900px) {
  .service-icon {
    display: none;
  }
  .single-service > .details > .service__price--icon > .price {
    margin-left: 0px;
  }
  .service__price--icon  {
    margin-top: 13px;
  }
}