@font-face {
    font-family: 'Poppins-Bold';
    src: url('./Poppins-Bold.ttf')
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./Poppins-Medium.ttf')
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('./Poppins-Regular.ttf')
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./Poppins-SemiBold.ttf')
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./Poppins-Light.ttf')
}