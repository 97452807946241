/* Main Widget */

.widget-main__container {
	width: 100%;
	height: 100%;
}

.widget-main__wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	margin-bottom: 40px;
}
.widget-main__wrapper__loading .widget-summary-generated-link {
	display: flex;
	align-items: center;
	justify-content: center;
  }

/*.widget-main__wrapper.diff-view{
	overflow: hidden;
}

.widget-main__wrapper.diff-view .widget-summary-link-title{
	text-align: center;
}

.widget-main__wrapper.diff-view .widget-summary-short-link{
	width: 100%;
	height: 100%;
	color: #829aaa !important;
	font-size: 10px;
	padding-left: 16px;
	margin-top: 7px;
	height: 16px;
}

.widget-main__wrapper.diff-view .widget-summary-copy-icon > svg{
	width: 100%;
	height: 100%;
}

.widget-main__wrapper.diff-view .widget-summary-copy-icon > svg .widget-copy-svg--g{
	stroke: #0f3c5c;
}*/

.profile-wrapper_dark .widget-main__wrapper.diff-view .widget-summary-generated-link{
	background-color: transparent;
	border: 1px solid #9fb1be;
}

.profile-wrapper_dark .widget-main__wrapper.diff-view .widget-summary-copy-icon > svg .widget-copy-svg--g{
	stroke: #fff;
}

.profile-wrapper_dark .widget-main__wrapper.diff-view .widget-summary-short-link{
	color: #fff;
}

.widget-main__wrapper::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 4px;
}

.widget-main__wrapper::-webkit-scrollbar-thumb {
	border-radius: 1.8px;
	background-color: #d0d8dd;
}

/* WidgetFlow */

.widget-flow__wrapper {
	position: relative;
}

.clock_icon {
	margin-top: 5px;
	position: absolute;
}

.widget-signup-inner {
	width: 368px;
	height: 497px;
	background-color: #29a7ff;
	position: absolute;
	z-index: 9;
	margin-left: 12px;
	border-radius: 14.3px;
	box-shadow: 0 5px 18px 0 rgba(15, 60, 92, 0.12);
	background-color: #29a7ff;
	margin-top: -2px;
}

.texfield-container {
	display: flex;
	flex-direction: column;
	width: 320px;
	height: 250px;
	justify-content: space-between;
	position: relative;
	margin: 0 auto;
	margin-top: 80px;
}

/*Widget Finish Page */

.widget-summary-link-box {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.widget-summary-link-title{
	width: 100%;
	font-family: 'Poppins-Regular';
	font-size: 14px;
	line-height: 24px;
	color: #474f5a;
	text-align: center;
	width: 250px;
	margin: auto;
}

.widget-summary-link-title strong{
	font-family: 'Poppins-Medium';
}

.profile-wrapper_dark .widget-summary-link-title {
	color: white;
}

.widget-summary-another-meeting {
	text-align: center;
	font-family: 'Poppins-Medium';
	font-size: 14px;
	color: #190e2f;
	text-decoration: underline;
	text-underline-position: under;
}

.widget-mobile-modal-wrapper .widget-summary-another-meeting{
	margin: -20px 0 !important;
}

.widget-summary-another-meeting:hover {
	cursor: pointer;
}

.widget-summary-link-description {
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: #9fb1be;
	margin: 0 auto;
	margin-top: -3px;
}

.widget-summary-generated-link {
	width: 100%;
	height: 40px;
	border-radius: 4px;
	background-color: rgba(218, 219, 221, 0.16);
	margin-top: 24px;
	position: relative;
	cursor: pointer;
}

.widget-summary-short-link {
	width: calc(100% - 40px);
	font-family: 'Poppins-Light';
	font-size: 12px;
	color: rgba(25, 14, 47, 0.72);
	padding-left: 16px;
	display: block;
	margin-top: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.widget-summary-copy-icon {
	position: absolute;
	top: 12px;
	right: 16px;
}

.widget-copy-svg--g {
	stroke: var(--profile-main-text-color);
}

/*.widget-tick-svg {
	fill: var(--profile-main-text-color);
}*/

.diff-view .widget-tick-svg{
	fill: #0F3C5C;
	opacity: 0.5;
}

.profile-wrapper_dark .diff-view .widget-tick-svg{
	fill: var(--profile-main-text-color);
}

/* Widget Payment */

.widget-payment-cards {
	display: flex;
	margin-top: 8px;
	margin-right: 12px;
}

.widget-payment-cards svg {
	width: 30px;
	height: 30px;
}

.visa_icon {
	/* background-image: url("../../assets/icons/visa.png"); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 47.5px;
	width: 80px;
	cursor: pointer;
}

.visa_icon:hover {
	opacity: 0.6;
}

.othercard_icon {
	margin-left: 3px;
	cursor: pointer;
}

.mastercard_icon {
	cursor: pointer;
	margin-left: -5px;
}

.americanexpress_icon {
	cursor: pointer;
}

.widget-payment-fields-column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 15px;
}

.widget-payment-fields-row {
	display: grid;
	grid-template-columns: 1fr 2fr;
	margin-top: 16px;
	grid-gap: 10px;
}

.widget-payment-save-label {
	margin-left: 5px;
	cursor: pointer;
	z-index: 10000;
	font-size: 14px;
}

.widget-payment-save-checkbox-container {
	z-index: 10000;
	margin: 10px 0 0 5px;
}

.widget-payment-cardnumber {
	height: 48px;
	border-radius: 12px;
	border: solid 1px #d8e0e5;
	background-color: #ffffff;
	position: relative;
	background: url("../../assets/icons/credit-card-3.png") no-repeat scroll 16px 15px;
	padding-left: 50px;
}

.widget-payment-cardnumber::placeholder {
	width: 85px;
	height: 16px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: rgba(58, 83, 101, 0.32);
	position: absolute;
}

#payment-cardholder {
	font-family: 'Poppins-Regular';
	font-size: 14px;
	padding: 0;
}

#payment-cardholder::placeholder {
	font-size: 13px;
	color: #000;
}

.MuiFormControl-root.widget-payment-cardholder {
	height: 44px;
	border-radius: 8px;
	border: solid 1px rgba(218, 219, 221, 0.64);
	background-color: #ffffff;
	position: relative;
	padding-left: 20px;
	outline: none;
	margin-top: 16px;
}

.widget-payment-cardholder fieldset {
	border: none!important;
}

.widget-payment-cardholder::placeholder {
	width: 137px;
	height: 16px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: red;
}

.widget-payment-cardholder input::placeholder {
	color: #000000;
}

.widget-payment-cvv {
	height: 48px;
	width: 100px;
	border-radius: 12px;
	border: solid 1px #d8e0e5;
	background-color: #ffffff;
	position: relative;
	background: url("../../assets/icons/cvv-icon.png") no-repeat scroll 16px 15px;
	padding-left: 50px;
	outline: none;
}

.widget-payment-cvv::placeholder {
	width: 28px;
	height: 21px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: rgba(58, 83, 101, 0.32);
}

.widget-payment-validation-date {
	height: 48px;
	margin-left: 5px !important;
	border-radius: 12px;
	border: solid 1px #d8e0e5;
	background-color: #ffffff;
	position: relative;
	background: url("../../assets/icons/validation-date-icon.png") no-repeat scroll 17px 12px;
	padding-left: 50px;
	outline: none;
}

.widget-payment-validation-date::placeholder {
	width: 95px;
	height: 21px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: rgba(58, 83, 101, 0.32);
}

/* Widget Registration Page */

.widget-signup-icons-box {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: relative;
}

.widget-signup-envelope,
.widget-signup-google,
.widget-signup-facebook {
	border-radius: 8px;
	background-color: rgba(218, 219, 221, 0.16);
	padding: 11.5px 47px;
	cursor: pointer;
}

.widget-signup-google img,
.widget-signup-facebook img,
.widget-signup-envelope img {
	width: 18px;
	height: 18px;
	object-fit: contain;
}

.widget-signup-terms > span {
	font-family: 'Poppins-Regular';
	text-decoration: underline;
	cursor: pointer;
	color: rgba(25, 14, 47, 0.64);
}

.profile-wrapper_dark .widget-signup-already-have-account-box span {
	color: white;
}

.widget-signup-already-have-account-box span {
	width: 170px;
	height: 17px;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #0f3c5c;
}

.profile-wrapper_dark .widget-signup-already-have-account-box span {
	color: white;
}

.widget-signup-already-have-account-box button {
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #00a1ff;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	background: none;
}

.widget-registration-headline {
	width: 113px;
	height: 20px;
	opacity: 0.8;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #0a283d;
	margin: 0 auto;
	margin-top: 32px;
}

.widget-registration-texfield-container {
	width: 100%;
	margin-top: -5px;
}

.widget-registration-texfield-container > .widget-registration-input{
	width: 100%;
}
.widget-registration-texfield-container > .widget-registration-input label {
	font-family: 'Poppins-regular';
}

.widget-registration-texfield-container > .widget-registration-input{
	margin-bottom: 12px;
	margin-top:5px
}

.widget-registration-input .MuiFormLabel-root.Mui-focused {
	color: rgba(58, 83, 101, 0.32);
}

.widget-registration-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-radius: 8px;
	border: solid 1px rgba(218, 219, 221, 0.64);
}

.widget-registration-input .MuiInputBase-root {
	font-size: 14px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000;
	height: 44px;
}

.send-message__email.widget-registration-input .MuiInputBase-root,
.send-message__text.widget-registration-input .MuiInputBase-root {
	border-radius: 12px;
}


.widget-registration-input.send-message__text .MuiOutlinedInput-root {
	height: unset!important;
}


.widget-registration-input .MuiInputLabel-outlined {
	margin-top: -4px;
	margin-left: 27px;
}

.widget-registration-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
	/* margin-left: 5px; */
	transform: translate(-16px, -3px) scale(0.75);
}


.widget-registration-input .MuiOutlinedInput-input {
	padding: 9.5px 14px;
	font-family: 'Poppins-Regular';
}

.widget-payment-fields-column .MuiOutlinedInput-root {
	height: 48px;
	border-radius: 12px;
}

.widget-payment-fields-column .MuiFormLabel-root.Mui-focused {
	color: rgba(58, 83, 101, 0.32);
}

.widget-payment-fields-column .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #d8e0e5;
	border-width: 1px;
	border-radius: 12px;
}

.widget-payment-fields-column .MuiInputBase-root {
	font-size: 14px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.widget-payment-fields-column .MuiInputLabel-outlined {
	margin-top: -4px;
	margin-left: 30px;
	color: rgba(58, 83, 101, 0.32) !important;

}

.widget-payment-fields-column .MuiInputLabel-outlined.MuiInputLabel-shrink {
	/* margin-left: 5px; */
	transform: translate(-16px, -3px) scale(0.75);
}

.widget-payment-fields-column .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #d8e0e5;
	border-width: 1px;
	border-radius: 12px;
}

.widget-payment-fields-row .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #d8e0e5;
	border-width: 1px;
	border-radius: 12px;
}

.widget-payment-fields-row .MuiOutlinedInput-root {
	height: 48px;
	border-radius: 12px;
}

.widget-payment-fields-row .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #d8e0e5;
	border-width: 1px;
	border-radius: 12px;
}

.widget-payment-fields-row .MuiInputBase-root {
	font-size: 14px !important;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.widget-payment-fields-row .MuiInputLabel-outlined {
	margin-top: -4px;
	margin-left: 30px;
	color: rgba(58, 83, 101, 0.32) !important;
}

.widget-payment-fields-row .MuiInputLabel-outlined.MuiInputLabel-shrink {
	/* margin-left: 5px; */
	transform: translate(-16px, -3px) scale(0.75);
}

.widget-payment-fields-row .MuiOutlinedInput-input {
	margin-left: 35px;
}

.texfield-container fieldset {
	border-radius: 12px;
	border: solid 1px #d8e0e5;
}

.widget-registration-texfield-container fieldset {
	border-radius: 8px;
	border: solid 1px rgba(218, 219, 221, 0.64);
}

.widget-registration-texfield-container #password + div > .text-field-icon-left{
	top: 12px;
}

.texfield-container .MuiIcon-colorAction {
	color: red;
}

.widget-registration-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border: 1px solid #d8e0e5;
}

.widget-payment-fields-column .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border: 1px solid #d8e0e5;
}

.widget-payment-fields-row .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border: 1px solid #d8e0e5;
}

.widget-term-and-service-wrapper {
	background: #ffffff !important;
}

.widget-Back-from-Terms-wrapper {
	display: flex;
	align-items: center;
	position: sticky;
	top: -1px;
	left: 0;
	background: var(--white);
	z-index: 1;
}

.widget-Terms-left-arrow-image:hover {
	cursor: pointer;
}

.widget-back-button:hover {
	cursor: pointer;
}

.widget-Terms-left-arrow-image {
	width: 20px;
	height: 20px;
	margin: 8px 12px 1px 5px;
	transform: rotate(180deg);
}

.widget-back-button {
	width: 38px;
	height: 16px;
	margin-top: 6px;
	font-family: 'Poppins-Medium';
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: var(--blue-2);
}

.widget-terms-header {
	width: 100%;
	margin: 10px 0px;
	font-family: "lvetica-Neue-Bold",sans-serif;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #0f3c5c;
}

.widget-terms-body p,
.widget-privacy-body p {
	text-align: justify;
}

.profile-wrapper_dark .widget-terms-header  {
	color: #fff;
}

.profile-wrapper_dark ul,
.profile-wrapper_dark ol {
	color: white;
	opacity: 0.8;
	font-weight: normal;
}

.widget-terms-body {
	height: 418px;
	font-size: 14px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #0f3c5c;
}

.text-field-icon-left {
	position: absolute;
	top: 15px;
	left: 15px;
	stroke: #bec1c4;
}

.Mui-focused .text-field-icon-left{
	stroke: #84d6f1;
}

.Mui-focused .text-field-icon-right.password-eye *{
	stroke: #84d6f1;
}

.text-field-icon-right {
	margin-right: -10px;
	margin-left: 3px;
	cursor: pointer;
}

.text-field-icon-right.password-eye * {
	stroke: #6A6E75;
}

.widget-signup-inner-bottom .MuiInputLabel-outlined {
	color: rgba(58, 83, 101, 0.32) !important;
	padding-left: 0px;
	padding-right: 5px;
	background-color: #fff;
}

.widget-mobile-modal-wrapper .widget-signup-inner-bottom .MuiInputLabel-outlined{
	background-color: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
	-webkit-box-shadow: 0 0 0 50px var(--white) inset !important;
	-webkit-background-clip: text;
}

input:-webkit-autofill {
	-webkit-text-fill-color:#0f3c5c;
}

.profile-wrapper_dark input:-webkit-autofill {
	-webkit-text-fill-color: #fff ;
}

.widget-mobile-modal-wrapper-dark input:-webkit-autofill {
	-webkit-text-fill-color: #fff ;
}

/*.widget-registration-texfield-container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: var(--cyan-2) !important;
}*/

.widget-registration-texfield-container .MuiFormLabel-root.Mui-focused {
	color: var(--cyan-2) !important;
}

.widget-payment-fields-column .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: var(--cyan-2) !important;
}

.widget-payment-fields-column .MuiFormLabel-root.Mui-focused {
	color: var(--cyan-2) !important;
}
.widget-payment-fields-row .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: var(--cyan-2) !important;
}

.widget-payment-fields-row .MuiFormLabel-root.Mui-focused {
	color: var(--cyan-2) !important;
}

.widget-payment-texfield-container fieldset {
	border-radius: 12px;
	border: solid 1px #d8e0e5;
}

.widget-signup-already-have-account-box {
	width: 100%;
	display: block;
	text-align: center;
	margin: 10px 0px;
}

.widget-signup-already-have-account-box > span {
	color: rgba(25, 14, 47, 0.64);
	font-size: 12px;
	font-family: 'Poppins-Regular';
}

.widget-signup-already-have-account-box > button{
	color: rgba(25, 14, 47, 0.72);
	font-size: 12px;
	font-family: 'Poppins-Medium';
}

.widget-signup-terms {
	font-size: 12px;
	opacity: 0.9;
	font-family: 'Poppins-Light';
	color: rgba(25, 14, 47, 0.64);
	text-align: center;
	margin-top: -5px;
}

.widget-mobile-modal-wrapper-dark .widget-signup-terms{
	color: #fff;
}

.widget-mobile-modal-wrapper-dark .widget-summary-link-title{
	color: #9fb1be;
}

.widget-privacy-body {
	width: 100%;
	height: 325px;
	font-size: 12px;
	text-align: left;
	color: #0f3c5c;
	font-family: 'Poppins-Regular';
}

.widget-privacy-body > p{
	font-size: 12px;
	font-family: 'Poppins-Regular';
}

.Add-Payment-Details {
	color: #190e2f;
	font-size: 16px;
	font-family: 'Poppins-SemiBold';
	text-align: center;
    padding: 0 7px;
	margin-top: 32px;
	margin-bottom: 32px;
}

.widget-mobile-modal-wrapper {
	position: absolute;
	background-color: var(--white);
	border-radius: 3%;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	width: 100%;
	bottom: 0;
	height: calc(100vh - 100px);
	text-align: center;
	-webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.widget-mobile-modal-wrapper .widget-payment-container .widget-choose-card-container {
	padding: 20px;
}

@-webkit-keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(400px);
		transform: translateY(400px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(400px);
		transform: translateY(400px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.widget-mobile-modal-wrapper:focus {
	outline: none;
}

:root {
	--white: #fff;
	--gray-1: #f4f5f6;
	--gray-2: #eff3f5;
	--blue-1: #137bdc;
	--blue-2: #0a283d;
	--blue-2-08: #0a283dcc;
	--blue-3: #3a5365;
	--green-1: #25e08d;
	--gray-blue: #0f3c5c;
	--light-gray: #9fb1be;
	--thin-gray: #d8e0e5;
	--thin-gray-2: #f4f5f6;
	--cyan: #00a1ff;
	--cyan-2: #2596e5;
	--gray-3: #f8f9fa;
}

:root .widget-mobile-modal-wrapper-dark,
:root .profile-wrapper_dark {
	--white: #1d3e54;
	--gray-1: #0b2c42;
	--gray-3: #30536d;
	--blue-2: #ffffff;
	--blue-2-08: #fff;
	--gray-blue: #fff;
	--gray-2: #eff3f5;
	--blue-1: #137bdc;
	--blue-3: #3a5365;
	--green-1: #25e08d;
	--light-gray: #9fb1be;
	--thin-gray: #d8e0e5;
	--thin-gray-2: #0a283d;
	--cyan: #00a1ff;
	--cyan-2: #2596e5;
}

.widget-mobile-modal-wrapper-dark h1,
.widget-mobile-modal-wrapper-dark h2,
.widget-mobile-modal-wrapper-dark h3,
.widget-mobile-modal-wrapper-dark h4,
.widget-mobile-modal-wrapper-dark span,
.widget-mobile-modal-wrapper-dark p,
.widget-mobile-modal-wrapper-dark textarea,
.widget-mobile-modal-wrapper-dark button,
.widget-mobile-modal-wrapper-dark input,
.widget-mobile-modal-wrapper-dark ol li{
	color: #fff!important;
}

.widget-mobile-modal-wrapper-dark span {
	color:var(--blue-2) !important;
}

.widget-mobile-modal-wrapper-dark .widget-summary-short-link {
	color: var(--profile-main-text-color)!important;
}

.widget-mobile-modal-wrapper-dark .calendar .DayPicker-Day--disabled{
	color: #ffffff52;
}

.widget-mobile-modal-wrapper-dark .calendar .DayPicker-Day.DayPicker-Day--outside {
	color: rgba(255, 255, 255, 0.12);
}

.widget-mobile-modal-wrapper-dark #clock-svg path, .widget-mobile-modal-wrapper-dark #clock-svg circle {
	stroke: rgba(255, 255, 255, 0.32);
}

.widget-mobile-modal-wrapper-dark .time-picker-time:disabled {
	background-color: #1d3e54;
	color: #ffffff33;
	opacity: 0.2;
}

.widget-mobile-modal-wrapper-dark .MuiFormLabel-root {
	color: rgba(255, 255, 255, 0.32) !important;
}

.widget-mobile-modal-wrapper .date {
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	color:var(--blue-2);
	margin: 20px;
}

.widget-mobile-modal-wrapper .serviceHeader {
	text-align: center;
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	color: var(--blue-2) !important;
	margin: 25px;
}

.widget-mobile-modal-wrapper .time {
	text-align: center;
}

.widget-mobile-modal-wrapper .MuiTypography-body2{
	flex: none;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #0a283d;
}

.widget-mobile-modal-wrapper .gig-picker-ul {
	margin: 0 auto;
	text-align: left;
}

.widget-mobile-modal-wrapper .time-picker::-webkit-scrollbar {
	display: none;
}

.widget-mobile-modal-wrapper .profile-flow-stack {
	height: 100%;
	scrollbar-width: none;
}

.widget-mobile-modal-wrapper .widget-signup-icons-box {
	margin-top: 10px;
}

.widget-mobile-modal-wrapper .widget-registration-texfield-container {
	margin: 16px auto;
}

.widget-mobile-modal-wrapper .profile-flow-stack::-webkit-scrollbar {
	display: none;
}

.widget-mobile-modal-wrapper .profile-flow-stack .widget-signup-inner-bottom {
	width: 100%;
}

.widget-mobile-modal-wrapper .widget-signup-already-have-account-box {
	width: 80%;
}

.widget-mobile-modal-wrapper .info {
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #0a283d;
	margin: 7%;
}

.widget-mobile-modal-wrapper .widget-flow__wrapper {
	width: 80%;
	margin: 7% auto;
}

.widget-mobile-modal-wrapper .Add-Payment-Details {
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color:var(--blue-2);
	margin: 7%;
}

.widget-mobile-modal-wrapper .widget-payment-cards {
	width: 100%;
}

.widget-mobile-modal-wrapper .MuiCardHeader-title {
	text-align: left;
}

.widget-mobile-modal-wrapper .MuiCardHeader-root{
	padding: 36px 16px;
}

.widget-mobile-modal-wrapper .subheader {
	display: flex;
	flex-direction: column;
}

.subheader .title {
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #0a283d;
	margin: 0;
	width: auto;
	max-width: 200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.subheader .price {
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: 1px;
	text-align: left;
	color: #25e08d !important;
	margin-left: 15px;
}

.subheader .time {
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #0a283d;
}

.subheader .date {
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: 1px;
	text-align: left;
	color: #25e08d !important;
	margin: 0 0 0 10px;
}

.time-and-date {
	align-self: flex-start;
	text-align: left;
}

.widget-signup-inner-bottom .widget-sign-up-or{
	display: block;
	position: relative;
	text-align: center;
	margin: 5px 0px 20px;
}

.widget-signup-inner-bottom .widget-sign-up-or:before{
	width: 100%;
	height: 1px;
	display: block;
	content: "";
	position: absolute;
	top: 60%;
	left: 0;
	background: #e7e8e9;
}

.widget-signup-inner-bottom .widget-sign-up-or > span{
	font-family: 'Poppins-Regular';
	color: #6a6e757e;
	font-size: 11px;
	opacity: 1;
	padding: 0 12px;
	background: #fff;
	position: relative;
	z-index: 10;
}

@supports (-webkit-touch-callout: none) {
	/* CSS specific to iOS devices */ 
	input,
	.MuiOutlinedInput-input,
	.MuiInputBase-input{
		font-size: 16px !important;
	}
}

.widget-sign-up-or hr.side-hr {
	border: none;
	border-bottom: 1px solid #d8e0e5;
	height: 0;
	width: 32%;
}

.widget-sign-up-or span {
	color: #0f3c5c;
	opacity: 0.5;
	font-size: 14px;
	margin-top: -2px;
}

.forgot-password {
	text-align: center;
	color: #474f5a;
	opacity: 0.72;
	font-family: 'Poppins-Regular';
	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	margin-bottom: 10px;
}

.forgot-password:hover{
	cursor: pointer;
}

.widget-forgot-headline {
	font-size: 16px;
	font-family: 'Poppins-Medium';
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: rgba(0, 0, 0, 0.56);
	padding: 0 4px;
	cursor: pointer;
}

.widget-forgot-headline svg {
	margin-right: 6px;
}

.forgot-pass-bck-nxt {
	display: flex;
	flex-direction: row;
	width: 300px;
	justify-content: center;
	align-items: center;
}

.forgot-pass-bck-nxt button.next-btn {
	padding: 12px!important;
	width: 49%;
}

.forgot-pass-bck-nxt .back-btn
.forgot-pass-bck-nxt-parent{
	position: static !important;
	bottom: unset !important;
}

.next-btn-mobile{
	align-self: center;
	background-color: var(--profile-secondary-color);
	background-image: var(--profile-secondary-color);
	width: 142px;
	height: 56px;
	border-radius: 12px;
	border: none;
	color: var(--profile-secondary-text-color);
	font-size: 16px;
	cursor: pointer;
}

.back-btn-mobile{
	margin-right: 16px;
	color: var(--gray-blue);
	background-color: var(--white);
	border: 1px solid var(--light-gray);
	width: 142px;
	height: 56px;
	border-radius: 12px;
	font-size: 16px;
}

.forgot-password__container {
	height: 100%;
	padding: 16px 0;
}

.widget-forget-subtitle {
	margin: 23px 0;
	color: rgba(25, 14, 47, 0.8);
	text-align: left;
	font-family: 'Poppins-Regular';
	font-size: 14px;
	margin-top: 13px;
}

.widget-mobile-modal-wrapper .closeIcon{
	width: 15px;
	height: 15px;
	margin: 18px;
}

.widget-mobile-modal-wrapper-dark .widget-mobile-modal-wrapper .closeIcon,
.widget-mobile-modal-wrapper-dark .widget-mobile-modal-wrapper .closeIcon > path{
	stroke: #fff;
}

.widget-mobile-modal-wrapper .modal-header {
	height: 67px;
	display: flex;
	justify-content: space-between;
	border-bottom: 4px solid var(--gray-1);
}

.widget-mobile-modal-wrapper .title-and-price {
	display: flex;
	text-align: left;
}

.widget-mobile-modal-wrapper .profile-picture {
	width: 50px;
	height: 50px;
	padding: 3px;
	background-color: var(--white);
	border-radius: 100vh;
	box-sizing: border-box;
}

.hr-sect {
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: #0f3c5c;
	margin: 8px 0px;
	width: 80%;
	margin: 0 auto;
}
.hr-sect:before,
.hr-sect:after {
	content: "";
	flex-grow: 1;
	background: #d8e0e5;
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 8px;
}

@media screen and (max-height: 700px) {
	.widget-mobile-modal-wrapper .widget-registration-texfield-container {
		height: 180px;
	}

	.widget-mobile-modal-wrapper .widget-signup-inner-bottom {
		margin-bottom: 0;
	}

	.widget-mobile-modal-wrapper .widget-signup-already-have-account-box {
		height: 30px;
	}
}


@media screen and (max-height: 750px) {
	.widget-mobile-modal-wrapper .widget-registration-texfield-container {
		height: 180px;
	}

	.widget-mobile-modal-wrapper .widget-signup-inner-bottom {
		margin-bottom: 0;
	}

	.widget-mobile-modal-wrapper .widget-signup-already-have-account-box {
		height: 30px;
		margin-top: 0!important;
	}
	.widget-mobile-modal-wrapper .widget-signup-terms {
		margin: 0 auto;
	}

	.profile-flow-stack .widget-signup-icons-box {
		height: 48px;
	}
}

.payment-loader {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.widget-payment-container {
	position: relative;
	height: 100%;
}

.widget-payment-container-loading {
	filter: blur(5px);
}

.send-message__form {
	height: 500px;
}

.send-message__email,
.send-message__text {
	width: 100%;
	margin-bottom: 20px!important;
}

.send-message__text .MuiOutlinedInput-multiline{
	padding: 5px 0;
}

.send-message__email {
	margin-top: 20px!important;
}

.send-message__button--wrapper .message-button {
	width: 100%;
	height: 56px;
	border: none;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.send-message__button--wrapper .message-button .message-btn__svg {
	position: absolute;
	left: 15px;
}

.send-message__button--wrapper .message-button h2 {
	margin-bottom: 0;
	color: var(--profile-secondary-text-color)!important;
	text-transform: unset!important;
}

.send-message__form .widget-registration-input .MuiOutlinedInput-input{
	margin-left: 0;
}

.send-message__back--button {
	font-size: 12px;
	color: black;
	cursor: pointer;
}

.profile-wrapper_dark .send-message__back--button {
	color: white;
}

.send-message__mobile--form {
	width: calc(100% - 48px);
	height: 100%;
	padding: 10px 25px;
}

.send-message__button--loader {
	margin-top: 0;
}

.send-message__status img {
	width: 34px;
	height: 34px;
}

.send-message__status,
.send-message__fail {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 18px;
}

.send-message__status span,
.send-message__fail span {
	font-size: 14px;
	color: #0f3c5c;
	width: calc(100% - 50px);
}

.profile-wrapper_dark .send-message__status span,
.profile-wrapper_dark .send-message__fail span {
	color: white;
}

.send-message__fail--icon {
	width: 34px;
	height: 34px;
	background: #fcd0ca;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
