.invitation-success__wrapper{
    width: 344px;
    height: 400px;
    background-color: #fff;
    border-radius: 12px;
    padding: 40px;
}

.invitation-success__Header{
    > svg {
        width: 110px !important;
    }
}

.success-modal--content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;

    > a {
        width: 214px;
        height: 44px;
        background-color: rgba(0, 174, 220, 0.1);

        font-family: "Poppins-Medium";
        font-size: 14px;
        color: rgba(0, 174, 220, 1);

        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        text-decoration: none;
        margin-top: 16px;
    }
}
.success-modal--header{
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #190E2F;
    margin-top: 16px;
    margin-bottom: 8px;
}

.success-modal--desc{
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: rgb(0, 0, 0);
    margin: 0;
}

.main-container .success-modal--content button.MuiButton-root {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #00AEDC;
    background-color: rgba(0, 174, 220, 0.1);
    border-radius: 4px;
    padding: 10px;
    text-transform: none;
    margin-top: 20px;
}
